.MiPocPage {
  height: 100%;
  &>header {
    display: none;
    height: 56px;
    &+div {
      height: 100%;
    }
  }
}
