.toast {
  &.commonToast {
    position: fixed;
    z-index: 99999;
    width: 100%;
    max-width: none;
    margin: 0 auto;
    bottom: -50px;
    text-align: center;
    box-shadow: none;
    border: none;
    background: none;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: none;
    opacity: 0.5;
    transition: bottom 0.2s;
    &:not(.modal-backdrop).show {
      opacity: 1 !important;
      bottom: 50px;
    }

    .toast-body {
      display: inline-block;
      margin: 0 auto;
      width: auto;
      max-width: none;
      min-width: auto;
      padding: 10px 24px;
      border-radius: 3px;
      background-color: #2c3238;
      color: #ffffff;
      text-align: center;
    }

    .material-icons {
      vertical-align: middle;
      float: right;
      cursor: pointer;
    }

    #toastMessage {
      color: #ffffff;
      font-family: 'Noto Sans KR';
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 19px;
    }

    #toastLink {
      margin-left: 12px;
      color: #e0205c;
      text-decoration: none;
      background-color: none;
      border-color: none;
    }

    .btn-primary {
      margin-left: 12px;
      font-size: 13px;
      border: none;
      background-color: #2c3238;
      padding: 0;

      &:active,
      &:focus {
        background-color: #2c3238;
      }
    }
  }
}
.mobile-app {
  @media screen and (max-width: 850px) {
    .commonToast {
      width: 100%;
      padding: 0 20px;
      left: 0;
      .toast-body {
        min-width: 100%;
        .tostMessage {
          font-size: 14px;
        }
      }
    }
  }
}
