#LoginContainer {
  height: 100% !important;
  width: 100%;
  background-color: #f5f8fa;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/loginbackground.jpg');
  .container-fluid {
    margin-left: 0px;
    background-color: #ffffff;
    height: 100%;
    width: 600px;
    margin: 0 auto;
    min-height: 100vh;
    .login-section-row {
      height: 80%;
    }
    .login-footer-row {
      height: 20%;
    }
    .row-footer {
      margin-left: 13%;
      margin-right: 13%;
      padding-top: 10px;
      border-top: 1px solid #d4d4d4;
    }
  }
}