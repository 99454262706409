#BookmarkContainer {
    display: flex;
    flex-flow: column;
    width: 232px;
    max-height: 500px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.22), 0 7px 10px 0 rgba(0,0,0,0.12);
    .Bookmark-header {
        flex: 0 0 auto;
        position: relative;
        padding-left: 47px;
        padding-top: 17px;
        height: 65px;
        color: #56606B;
        font-family: "Noto Sans KR";
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.4px;
        line-height: 33px;
        &:before {
            content: "";
            position: absolute;
            top: 24px;
            left: 25px;
            display: inline-block;
            width: 12px;
            height: 20px;
            background: url('../../icons/ic-bookmark.svg') no-repeat center;
        }
        .Bookmark-title{
            height: 33px;
            color: #56606B;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: -0.4px;
            line-height: 33px;
        }
     }
     .Bookmark-body{
        flex: 1 1 auto;
        margin: 0 9px;
        overflow-y: auto;
         ul{
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
            list-style: none;
            .Bookmark-body-folder-title{
                position: relative;
                display: flex; 
                align-items: center;
                min-height: 36px;
                padding: 6px 10px 6px 38px;
                border-radius: 18px;
                color: #8996A3;
                font-size: 15px;
                font-family: "Noto Sans KR";
                font-weight: 700;
                letter-spacing: -0.12px;
                line-height: 22px;
                &:before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: 14px;
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background: url('../../icons/ic-folder.svg') no-repeat center;    
                }
                &:hover {
                    background-color: #E0205C;  
                    color: #FFFFFF;
                    cursor: pointer;
                    &:before {
                        background: url('../../icons/ic-folder-white.svg') no-repeat center,
                    }
                }
            }
         }
     }
     .Bookmark-footer{
        position: relative;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        height: 40px;
        padding-left: 48px;
        border-top: 1px solid #E9EFF5;
        color: #26B9D1;
        font-size: 13px;
        letter-spacing: 0.1px;
        line-height: 19px;
        cursor: pointer;          
        &:before {
            content: "";
            position: absolute;
            top: 15px;
            left: 28px;
            width: 7px;
            height: 7px;
            background: url('../../icons/ic-plus.svg') no-repeat center;
        }
        .Bookmark-footer-title{
            height: 19px;
            width: 76px;
            color: #26B9D1;
            font-size: 13px;
            letter-spacing: 0.1px;
            line-height: 19px;
        }
    }  
}

#popoverBookmarkContainer {
    border: none;
    margin: 0;
    .arrow {
        display: none;
    }
    &.bs-popover-bottom {
        margin-top: -35px;
    }
}

@media all and (-ms-high-contrast:none) {
    /* IE11 */
    *::-ms-backdrop, #BookmarkContainer {
      box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.22), 0px 7px 10px 0px rgba(0,0,0,0.12) !important;
    } 
  }