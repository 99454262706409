.DocumentViewerSection {
  width: 100%;
  form {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;

    .document-content-area {
      flex: 1 1 auto;
      overflow-y: auto;
      .content {
        height: 100%;
        margin: 10px 5px 5px 10px;
        color: #424952;
        font-size: 20px;
        letter-spacing: 0.1px;
      }
    }
  }
  #editorTitle {
    flex: 0 0 auto;
    display: inline-flex;
    width: calc(100% - 115px);
    height: auto;
    padding: 0;
    border: none;
    box-shadow: none;
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Noto Sans KR" !important;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.4px;
    &::-webkit-input-placeholder {
      /* Edge */
      color: #DAE3ED;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #DAE3ED;
    }
    &::placeholder {
      color: #DAE3ED;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }
    &+div {
      flex: 1 1 auto;
      margin-top: 22px;
      border-top: 1px solid #DAE3ED;
    }
  }
}