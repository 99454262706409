.rs-drawer-wrapper {
  .rs-drawer {
    top: 90px;
    width: 688px;
    height: calc(100% - 90px);
    border: none;
    border-radius: 0;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22), 0 7px 10px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.2s;
    .rs-drawer-body {
      margin: 0;
      height: 100% !important;
      overflow: hidden !important;
    }
    &.maximize {
      top: 0;
      width: 100%;
      height: 100%;
      section {
        &>.btn-group {
          .btn-icon {
            i {
              &.ic-maximize {
                background-image: url("../../icons/ic-minimize.svg") !important;
              }
            }
          }
        }
      }
    }
  }
}

#DocumentContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 0;
  .document-body {
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    margin: 0;
    padding: 24px 0 0 35px;
    overflow: hidden;
    aside {
      flex: 0 0 auto;
      width: 178px;
    }
    section {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      margin: 0 0 0 48px;
      padding: 0 0 3px 2px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      &>.btn-group {
        position: absolute;
        top: 0;
        right: 20px;
        width: 100px;
        height: 32px;
        z-index: 1;
        .btn-icon {
          display: flex;
          align-items: center;
          position: relative;
          width: 32px;
          height: 34px;
          padding: 0;
          margin: 0 0 0 20px;
          border: none;
          border-radius: 50%;
          background: none;
          i {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &.ic-maximize {
              width: 24px;
              background-image: url("../../icons/ic-maximize.svg");
            }
            &.ic-close {
              background-image: url("../../icons/ic-close.svg");
            }
          }
        }
      }
    }
  }
  footer {
    position: absolute;
    bottom: -70px;
    width: 100%;
    height: 70px;
    padding: 0;
    background-color: #F5F8FA;
    flex: 0 0 auto;
    transition: all 0.5s;
    .document-button-group {
      display: flex;
      align-items: center;
      padding: 17px 34px 0 32px;
      &.document-type-list {
        justify-content: flex-end;
        padding: 17px 34px 0 232px;
      }
      .form-group {
        flex: 1 1 auto;
        margin: 0;
        .form-check {
          display: flex;
          align-items: center;
          height: 36px;
          padding-left: 26px;
          input[type="checkbox"] {
            visibility: hidden;
          }
          .form-check-label {
            position: relative;
            color: #424952;
            font-size: 12px;
            letter-spacing: 0.1px;
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: -26px;
              display: inline-block;
              width: 18px;
              height: 18px;
              border: 1px solid #A5B3C2;
              border-radius: 3px;
            }
          }
          input:checked ~ .form-check-label {
            &:before {
              background-color: #26B9D1;
              border-color: #26B9D1;
            }
            &:after {
              content: "」";
              position: absolute;
              top: -3px;
              left: -20px;
              font-size: 17px;
              transform: rotate(45deg);
              color: #FFFFFF;
            }
          }
        }
      }
      .btn {
        position: relative;
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 12px;
        border-radius: 3px;
        border: 1px solid #BEC9D4;
        background-color: #FFFFFF;
        color: #56606B;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1px;
        line-height: 17px;
        &:hover {
          border-color: #DAE3ED;
        }
        &:active,
        &:focus {
          background-color: #F5F8FA;
        }
        &:disabled {
          opacity: 0.4;
        }
        &+.btn {
          margin-left: 6px;
        }
        &.share {
          padding-left: 30px;
          &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            width: 14px;
            height: 14px;
            background: url("../../icons/ic-share-mini.svg") no-repeat center;
          }
        }
        &.delete {
          padding-left: 30px;
          &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            width: 14px;
            height: 14px;
            background: url("../../icons/ic-trash-1214.svg") no-repeat center;
          }
        }
        &.list {
          padding-left: 30px;
          &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            width: 14px;
            height: 14px;
            background: url("../../icons/ic-list.svg") no-repeat center;
          }
        }
        &.save {
          padding-left: 30px;
          background-color: #424952;
          color: #FFFFFF;
          &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            width: 14px;
            height: 14px;
            background: url("../../icons/ic-check-white.svg") no-repeat center;
          }
          &:hover {
            background-color: #6B7682;
          }
          &:active,
          &:focus {
            background-color: #2C3238;
          }
        }
      }
    }
  }
  &.checked {
    height: calc(100% - 70px);
    footer {
      bottom: 0;
    }
  }
}