@import '../../Mixin';
@import './var';

.noData {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin: 104px;
    align-items: center;
    color: #8996a3;
    font-family: 'Noto Sans KR';
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.27px;
    text-align: center;
    svg {
      margin-bottom: 48px;
    }
}