#BookmarkContents {
  .list-group {
    margin-top: 60px;
    flex: 0 0 auto;
    .list-group-item {
      display: flex;
      width: 960px;
      padding: 18px 25px 18px 0px;
      border: none;
      background-color: #ffffff;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
      cursor: pointer;
      & + .list-group-item {
        margin-top: 10px;
      }
      &.active {
        z-index: unset;
        &:before {
          background: url('../../icons/ic-checked.svg') no-repeat center;
          background-size: contain;
          border: none;
        }
      }
      .figure {
        width: 110px;
        height: 73px;
        flex: 0 0 auto;
        margin: 0;
        .figure-img {
          position: absolute;
          margin: 0;
          width: 110px;
          height: 73px;
          object-fit: cover;
        }
        .figure-caption {
          display: none;
        }
        & + .content {
          margin-left: 18px;
        }
      }
      .content {
        flex: 1 1 auto;
        width: 100%;
        overflow: hidden;
        h4 {
          margin-bottom: 7px;
          line-height: 18px;
          color: #2c3238;
          font-family: 'Noto Sans KR';
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0.06px;
        }
        p {
          margin-bottom: 3px;
          color: #424952;
          font-family: 'Noto Sans KR';
          font-size: 12px;
          letter-spacing: 0.1px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        address {
          margin: 0;
          color: #8996a3;
          font-family: 'Noto Sans KR';
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 18px;
        }
        i {
          flex: 0 0 auto;
          height: 22px;
          padding: 1px 10px;
          border-radius: 10px;
          background-color: #56606B;
          color: #FFFFFF;
          font-family: "Noto Sans KR";
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 18px;
          text-align: center;
          font-style: normal;
        }
      }
      .btn-group {
        display: flex;
        align-items: center;
        width: 200px;
        .btn-icon {
          display: flex;
          height: 24px;
          padding: 0;
          background-color: transparent;
          border: none;
          color: #424952;
          font-family: 'Noto Sans KR';
          font-size: 12px;
          letter-spacing: 0.1px;
          svg {
            display: flex;
            height: 20px;
            margin-right: 6px;
          }
          & + .btn-icon {
            margin-left: 22px;
          }
          &.ic-comment {
            svg {
              g {
              }
            }
          }
          &.ic-share {
            svg {
              g {
              }
            }
          }
          &.ic-delete {
            margin-left: 28px;
            &:before {
              content: '';
              display: inline-block;
              width: 18px;
              height: 18px;
              background: url('../../icons/ic-trash-1517.svg') no-repeat center;
            }
          }
        }
      }

      .rs-checkbox-wrapper::before,
      .rs-checkbox-wrapper::after,
      .rs-checkbox-wrapper .rs-checkbox-inner::before,
      .rs-checkbox-wrapper .rs-checkbox-inner::after {
        width: 24px;
        height: 24px;
        top: calc(50% + 3px);
        left: 0px;
      }
      .rs-checkbox-checked {
        .rs-checkbox-wrapper {
          .rs-checkbox-inner::after {
            transform: rotate(45deg) scale(0.5);
            width: 18px;
            height: 30px;
            border-right-width: 3px;
            border-bottom-width: 3px;
          }

          .rs-checkbox-inner::after,
          .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
            margin-top: -6px;
            margin-left: 3px;
          }

          .rs-checkbox-inner::before,
          .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
            background-color: #26b9d1;
            border-color: #26b9d1;
          }
        }
      }

      .rs-checkbox {
        margin-right: 7px;
        padding: 0px 10px;
      }
    }
  }
}
