@import '../../Mixin.scss';

.SearchLeft {
  width: 122px;
  @include margin(left,102px);
  @include margin(right,22px);
  .search-left-list {
    &:last-child {
      @include padding(bottom,0px);
    }
    .search-left-text {
      color: #8996A3;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.2px;
      line-height: 22px;
      @include padding(bottom,15px);
      &:hover {
        color:#2C3238;
      }
      &.active {
        color:#2C3238;
      }
    }
    .scout {
      width: 119px;
      .circle {
        width: 14px;
        background-size: 14px;
        background-image: url('../../icons/circle.png');
        @include background(0px 4px);
      }
      .scout-word {
        @include padding(left, 2px);
      }
      background-image: url('../../icons/Shape.svg');
      @include background(107px 6px);
    }

    .google-search {
      width: 90px;
      @include padding(left,60px);
      background-image: url('../../icons/google_text.svg');
      @include background(0px 3px);
    }
  }

}





