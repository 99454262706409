// Override default variables before the import

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$primary: #a50034 !default;
$secondary: #439099 !default;
$dark: #333333 !default;

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/NotoSans-Regular.woff2') format('woff2'), url('/fonts/NotoSans-Regular.woff') format('woff'),
    url('/fonts/NotoSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/NotoSans-Bold.woff2') format('woff2'), url('/fonts/NotoSans-Bold.woff') format('woff'),
    url('/fonts/NotoSans-Bold.otf') format('opentype');
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
