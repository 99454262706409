#ShareMember {
  height: 20px;
  display: inline-flex;
  align-items: center;
  img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #E9EFF5;
    overflow: hidden;
  }
  .memberInfo{
    color: #56606B;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 19px;
    margin-left: 5px;
    white-space: nowrap;
    .jobTitle{
      margin-left: 4px;
    }
    .memberEngName{
      margin-left: 4px;
    }
    .memberEngName::before{
      content:"("
    }
    .memberEngName::after{
      content:")"
    }
  }
  .companyInfo{
    color: #8996A3;
    font-family: "Noto Sans CJK KR";
    font-size: 11px;
    letter-spacing: 0.15px;
    line-height: 17px;
    margin-left: 7px;
    .department{
      margin-left: 5px;
    }
    .memberCompany::before{
      content:"@"
    }
  }
}

.search-member-select {
  #ShareMember {
    min-height: 26px;
    display: inline-flex;
    align-items: center;
    img {
      flex: 0 0 auto;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      background-color: #E9EFF5;
      overflow: hidden;
    }
    .memberInfo {
      flex: 1 1 auto;
      color: #000000;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.1px;
      line-height: 19px;
      margin-left: 9px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      b {
        font-weight: 700;
      }
      .jobTitle{
        margin-left: 4px;
      }
      .memberEngName{
        margin-left: 4px;
      }
      .memberEngName::before{
        content:"("
      }
      .memberEngName::after{
        content:")"
      }
    }
    .companyInfo {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .department {
      margin-left: 3px;
    }
  }
}