@import '../../../Mixin';
@import '../var';

.list{
  .list-box{
    .social {
      @include absolute(null,0,29px,null);
      width: 110px;
      height: 17px;
      color: #6B7682;
      font-size: 11px;
      letter-spacing: 0.15px;
      line-height: 17px;
      .bookmark {
        @include padding(left,14px);
        background-image: url('../../../icons/bookmark.svg');
        @include background(0px 2px);
        width: 27px;
      }
      .comment {
        @include margin(left,15px);
        @include padding(left,19px);
        background-image: url('../../../icons/comment.svg');
        @include background(0px 2px);
        width: 27px;
      }
      .share {
        @include margin(left,17px);
        background-image: url('../../../icons/share3.svg');
        @include background(0px 2px);
        width: 14px;
      }
    }
  }
}
