/* font-face */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/notokr-light.woff2") format("woff2"), url("./fonts/notokr-light.woff") format("woff"), url("./fonts/notokr-light.eot") format("opentype"), url("./fonts/notokr-light.ttf") format("truetype");
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/notokr-regular.woff2") format("woff2"), url("./fonts/notokr-regular.woff") format("woff"), url("./fonts/notokr-regular.eot") format("opentype"), url("./fonts/notokr-regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/notokr-bold.woff2") format("woff2"), url("./fonts/notokr-bold.woff") format("woff"), url("./fonts/notokr-bold.eot") format("opentype"), url("./fonts/notokr-bold.ttf") format("truetype");
}

/* pointer-events */
ul {
  list-style:none;
}

.no-touch {
  pointer-events: none;
}

.touch {
  pointer-events: auto;
}

/* scroll */
.no-scroll {
  overflow: initial;
}

.vscroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.hscroll {
  overflow-x: auto;
  overflow-y: hidden;
}

.auto-scroll {
  overflow: auto;
}

/* absolute */
.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.fullscreen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.fill {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: -webkit-fill-available;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}

/* relative */
.relative {
  position: relative;
}

/* static */
.static {
  position: static;
}

/* hidden */
.hidden {
  visibility: hidden;
}

.no-display {
  display: none;
}

/* block */
.block {
  display: block;
}

/* sizing */
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

/* flex */
.flex-in {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex-one {
  flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
}

.max-full-width {
  max-width: 100%;
}

/* flex-direction */
.vbox {
  flex-direction: column;
  -ms-flex-direction: column;
}

.hbox {
  flex-direction: row;
  -ms-flex-direction: row;
}

.vrbox {
  flex-direction: column-reverse;
}

.hrbox {
  flex-direction: row-reverse;
}

/* center */

.center {
  justify-content: center;
  align-items: center;
}

/* justify-content */

.start {
  justify-content: start;
}

.jc-center {
  justify-content: center;
}

.end {
  justify-content: flex-end;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

/* align-items */

.cross-start {
  align-items: start;
}

.cross-center {
  align-items: center;
}

.cross-end {
  align-items: flex-end;
}

.cross-stretch {
  align-items: stretch;
}

.cross-baseline {
  align-items: baseline;
}

/* flex-wrap */

.nowrap {
  flex-wrap: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.rev-wrap {
  flex-wrap: wrap-reverse;
}

/*  */
.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.wspace-nowrap {
  white-space: nowrap;
}

/* dim */

.dim {
  background-color: rgba(0,0,0,0.5);
}

/* cursor default */

.default {
  cursor: default;
}

.flip-y {
  transform: rotate(180deg);
}

.cursor_pointer {
  cursor: pointer;
}

.break-word {
  word-break:break-word;
}