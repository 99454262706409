#ErrorPage {
  position: relative;
  padding: 0;
  width: 100%;

  .container {
    width: 1260px;
    height: 100%;
    max-width: none;
    & > .row {
      height: 100%;
    }
  }

  .ErrorPage-title-target {
    font-family: LGSmHaTB;
    font-size: 80px;
    line-height: 60px;
    /* identical to box height, or 75% */
    color: #000000;
  }

  .ErrorPage-title {
    width: 500px;
    margin-top: 140px;
    padding-left: 100px;
  }

  .ErrorPage-content {
    width: 1000px;
    margin-top: 40px;
    padding-left: 100px;
    font-family: LGSmHaTR;
    font-size: 40px;
    line-height: 60px;
    color: #000000;
    .contents {
      width: 1000px;
      font-family: LGSmHaTR;
      font-size: 40px;
      line-height: 60px;
    }
    .contents-sub {
      font-family: LG Smart_H2.0;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .ErrorPage-button {
    width: 500px;
    margin-top: 40px;
    padding-left: 100px;

    .btn-back {
      border-radius: 4px;
      background-color: #439099;
      border-color: #439099;
      &:focus,
      &:active {
        box-shadow: 0px 0px 3px 3px rgba(#439099, 0.5);
      }
    }
  }

  a.link-main {
    padding-left: 20px;
    font-family: LGSmHaTR;
    font-size: 16px;
    line-height: 24px;
    color: #439099;
  }

  .primary-button {
    background-color: #7f7f7f;
    color: #ffffff;
  }

  .link-button-list {
    display: inline;
    margin-top: 42px;
  }

  .link-another-menu {
    padding: 10px;
  }

  .row-footer {
    margin-top: 150px;
    padding-top: 28px;
    border-top: 1px solid #d4d4d4;
  }

  .w1Image {
    position: absolute;
    top: 180px;
    left: -305px;
    opacity: 0.15;
  }

  .w2Image {
    position: absolute;
    bottom: 82px;
    right: -470px;
    z-index: 0;
    opacity: 0.6;
  }

  .logoImage {
    position: absolute;
    top: 180px;
    left: 231px;
  }

  .mLogoImage {
    display: none;
  }

  .link-another-menu {
    display: none;
  }
}

@media (max-width: 850px) {
  #ErrorPage {
    overflow-x: hidden;
    .container {
      width: 100%;
      padding: 0;
      & > .row {
        flex-flow: column;
        margin: 0;
        & > .col {
          padding: 0;
          &:first-child {
            padding-bottom: 120px;
            flex-basis: auto;
          }
        }
      }
    }

    .ErrorPage-title {
      width: 100%;
      margin: 0;
      padding: 100px 50px 0;
      .ErrorPage-title-target {
        font-size: 80px;
        line-height: 60px;
        /* identical to box height, or 75% */
        color: #000000;
      }
    }

    .ErrorPage-description {
      width: 100%;
      margin-top: 40px;
      padding: 0 50px;
      p {
        opacity: 0.8;
        letter-spacing: -0.4px;
        margin-bottom: 12px;
        &:first-child {
          opacity: 0.9;
        }
      }
    }

    .w1Image,
    .logoImage {
      display: none;
    }

    .mLogoImage {
      display: inline-block;
      position: absolute;
      top: -104px;
      left: auto;
      right: -48px;
      z-index: -1;
    }

    .link-button-list {
      margin-top: 90px;
    }

    .link-button {
      float: left;
      height: 48px;
      line-height: 48px;
      padding: 0 15px;
      margin: 0 8px;
      font-size: 16px;
    }

    .link-another-menu {
      display: inline-block;
      width: 38px;
      height: 48px;
      line-height: 12px;
      padding: 10px 6px;
      transform: rotate(90deg);
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      align-items: center;
      cursor: pointer;
    }

    #linkButtonEmployeeAuthentication {
      display: none;
    }

    .primary-button {
      background-color: #000000;
      border-color: #000000;
    }
  }
}
