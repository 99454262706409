#ListTypeContentsCard {
  width: 600px;
  height: 350px;
  flex: none;

  .list {
    counter-reset: number;
    list-style-type: none;
    padding-left: 0px;
    line-height: 1.7rem;
    padding-top: 5px;
  }

  /* The "\a0" is a space */
  .list li::before {
    counter-increment: number;
    content: counter(number)"\a0";
    color: rgb(18, 205, 230);
    font-size:0.8rem;
    font-weight: bold;
    margin-right: 10px;
  }

  .cardTitle{
    font-weight: bold;
    font-size: 0.9rem;
    color: #6d7073;
  }

  .cardDesc{
    max-height: 3.1rem;
    text-overflow: ellipsis;
    line-height: 1.1;
    overflow: hidden;
    color:#292929;
    font-size: 1rem;
  }
  .cardDesc:hover{
    font-weight: bold;
    color:black;
    cursor: pointer;
  }

  .publisher{
    font-weight: bold;
    font-size: 0.75rem;
    color:#a3a5a7;
    float: right;
  }
}