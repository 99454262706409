.DocumentEditorSection {
  width: 100%;
  form {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
  }
  #editorTitle {
    flex: 0 0 auto;
    display: inline-flex;
    width: calc(100% - 115px);
    height: auto;
    padding: 0;
    border: none;
    box-shadow: none;
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Noto Sans KR" !important;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.4px;
    &::-webkit-input-placeholder {
      /* Edge */
      color: #DAE3ED;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #DAE3ED;
    }
    &::placeholder {
      color: #DAE3ED;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }
    &+div {
      flex: 1 1 auto;
      margin-top: 22px;
      border-top: 1px solid #DAE3ED;
    }
  }
  .tui-editor-defaultUI {
    border: none;
    .te-toolbar-section {
      height: 48px;
      .tui-editor-defaultUI-toolbar {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0;
        border-bottom: 1px solid #E9EFF5;
        button {
          margin: 0 3px;
          padding: 0;
          border-radius: 50%;
          border: none;
          &:focus {
            outline: none;
            background-color: #E9EFF5;
          }
          &:hover {
            background-color: #F5F8FA;
          }
          &:disabled {
            opacity: 0.4;
          }
        }
      }
    }
    .te-editor-section {
      .tui-editor-contents {
        padding: 16px;
      }
    }
  }
}

.rs-drawer {
  .DocumentEditorSection {

  }
}