.rs-drawer-wrapper {
  .noticeDrawer {
    &.rs-drawer {
      width: 460px;
    }
  }
}

#Notice {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 24px 12px 20px;
  .header {
    flex: 0 0 auto;
    position: relative;
    padding: 0 10px 0 18px;
    h3 {
      position: relative;
      padding-left: 38px;
      color: #56606B;
      font-family: "Noto Sans KR";
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.4px;
      line-height: 33px;
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 25px;
        height: 24px;
        background: url("../../icons/ic-notice.svg") no-repeat center;
      }
    }
    .btn-group {
      position: absolute;
      top: 0;
      right: 20px;
      width: 42px;
      height: 32px;
      z-index: 1;
      .btn-icon {
        display: flex;
        align-items: center;
        position: relative;
        width: 32px;
        height: 34px;
        padding: 0;
        margin: 0 0 0 20px;
        border: none;
        border-radius: 50%;
        background: none;
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          &.ic-close {
            background-image: url("../../icons/ic-close.svg");
          }
        }
      }
    }
  }
  .body {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    margin: 20px 0 0;
    padding: 0;
    overflow-y: auto;
    .notice-detail {
      display: flex;
      flex-flow: row;
      flex: 0 0 auto;
      position: relative;
      margin: 0;
      padding: 12px 15px;
      &:hover {
        background-color: #F5F8FA;
        .btn-icon {
          display: flex;
          align-items: center;
          position: absolute;
          top: 10px;
          right: 10px;
          width: 12px;
          height: 12px;
          padding: 0;
          border: none;
          border-radius: 50%;
          background: none;
          i {
            display: inline-block;
            width: 12px;
            height: 12px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &.ic-close {
              background-image: url("../../icons/ic-close.svg");
            }
          }
        }
      }
      .btn-icon {
        display: none;
      }
      aside {
        flex: 0 0 auto;
        width: 38px;
        .avatarImg {
          position: relative;
          width: 38px;
          height: 38px;
          padding: 0;
          margin: 0;
          border: none;
          border-radius: 50%;
          background: none;
          &.on {
            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: 5px;
              height: 5px;
              background-color: #E0205C;
              border-radius: 50%;
            }
          }
          img {
            display: inline-block;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
      section {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        margin: 0;
        margin-left: 16px;
        padding: 0;
        overflow: hidden;
        .notice-item {
          flex: 1 1 auto;
          overflow: hidden;
          margin: 0px 0px;
          .sharedList {
            display: flex;
            flex-flow: column;
            overflow: hidden;
          }
          .createdMember {
            display: inline-flex;
            align-items: center;
            color: #2C3238;
            font-family: "Noto Sans KR";
            font-size: 15px;
            letter-spacing: -0.2px;
            line-height: 22px;
            span {
              margin-left: 8px;
              color: #8996A3;
              font-size: 12px;
              letter-spacing: 0.1px;
              line-height: 18px;
            }
          }
          .sharedComment {
            margin-top: 3px;
            color: #56606B;
            font-size: 13px;
            letter-spacing: 0.1px;
            line-height: 19px;
          }
          .sharedList {
            margin-top: 5px;
            .sharedDetail {
              display: flex;
              flex-wrap: nowrap;
              color: #8996A3;
              font-size: 12px;
              letter-spacing: 0.1px;
              line-height: 18px;
              &:hover {
                .sharedUrl {
                  text-decoration: underline;
                }
              }
              a {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              span {
                flex: 0 0 auto;
                margin-left: 8px;
              }
            }
            .sharedUrl {
              color: #26B9D1;
              font-size: 13px;
              font-weight: 700;
              cursor: pointer;
            }
          }
        }
      }
    }
    p {
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05px;
      line-height: 19px;

      &.subtitle {
        font-weight: 700;
      }
    }
  }
}
