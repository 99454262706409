#LoginSection {
  // 추후 수정
  //background: url(../../../../icons/sciencepark_compressed.jpg) no-repeat center;
  position: relative;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100%;
  overflow: hidden;
  &:after {
    content: '';
    background-position-x: calc(50% + 790px);
    background-position-y: 540px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 0;
    opacity: 0.6;
  }
  .container {
    height: 100%;
    max-width: none;
    & > .row {
      height: 100%;
      overflow: hidden;
    }
  }
  h2 {
    font-family: "Noto Sans KR";
    font-size: 40px;
    color: #000000;
  }
  p {
    font-size: 14px;
  }
  .LoginSection-title-target {
    margin-right: 14px;
    font-family: "Noto Sans KR";
    font-size: 40px;
    font-weight: 700;
    color: #000000;
  }
  .LoginSection-title {
    width: 520px;
    padding-top: 140px;
    padding-left: 90px;
  }
  .LoginSection-description {
    width: 500px;
    margin-top: 15px;
    margin-bottom: 30px;
    padding-left: 10px;
    p {
      line-height: 30px;
      font-size: 16px;
      color: #000000;
      opacity: 0.6;
      word-break: keep-all;
      &:first-child {
        font-weight: bold;
      }
    }
  }
  .LoginSection-description > p > a {
    color: black;
  }
  .LoginSection-form {
    position: relative;
    padding: 0 90px;
    width: 100%;
    z-index: 1;
    margin-top: 50px;
    .Loginform-component {
      width: 100%;
      margin-bottom: 60px;
    }
    .LoginButton-component {
      margin-bottom: 58px;
    }
  }
  .link-list {
    padding-top: 10px;
  }
  .link-list > .link-button {
    display: inline;
    padding: 0 10px;
    color: black;
    align-items: left;
  }
  .link-list > .link-button > a {
    color: black;
  }
  .link-loginForm {
    margin-top: 18px;
    margin-right: 30px;
    font-size: 16px;
    color: #000000;
    border-bottom: 1px solid;
    &:hover {
      text-decoration: none;
    }
  }
  #verifyNonSsoMember {
    margin-right: 0px;
  }
  .row-info {
    padding-bottom: 10px;
    .col {
      font-size: 14px;
    }
  }
  .row-linker {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 50px;
    font-weight: bold;
  }
  .backLoginButtonForm {
    width: 500px;
    margin-bottom: 30px;
    margin-top: 15px;
    padding-left: 10px;
    .material-icons {
      width: 20px;
      height: 16.8px;
      font-size: 20px;
      line-height: 1.3;
      position: absolute;
      cursor: pointer;
    }
    a {
      margin-left: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #000000;
      opacity: 0.6;
      word-break: keep-all;
      font-weight: bold;
    }
  }

  .login-button-form {
    align-items: center;
  }

  .nonmemberReservation {
    width: 208px;
    height: 208px;
    text-align: center;
    margin-right: 19px;
    border-radius: 24px;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    .material-icons.md-48 {
      font-size: 65px;
      position: absolute;
      margin-top: 50px;
    }
    .button-text-align {
      align-self: flex-end;
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  .nonmemberHistory {
    width: 208px;
    height: 208px;
    text-align: center;
    border-radius: 24px;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    .material-icons.md-48 {
      font-size: 65px;
      position: absolute;
      margin-top: 50px;
    }
    .button-text-align {
      align-self: flex-end;
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  .lgLogo {
    padding-bottom: 39.5px;
  }
  a {
    cursor: pointer;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .nonmemberReservation {
    width: 208px;
    height: 208px;
    text-align: center;
    line-height: 300px;
    margin-right: 19px;
    border-radius: 24px;
    font-size: 24px;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    .material-icons.md-48 {
      font-size: 65px;
      position: absolute;
      margin-top: 50px;
    }
  }
  .nonmemberHistory {
    width: 208px;
    height: 208px;
    text-align: center;
    line-height: 300px;
    margin-right: 19px;
    border-radius: 24px;
    font-size: 24px;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    .material-icons.md-48 {
      font-size: 65px;
      position: absolute;
      margin-top: 50px;
      margin-left: 48px;
    }
  }
}

@media only screen and (max-width: 850px) {
  #LoginSection {
    padding-left: 0%;
    overflow-x: hidden;
    .container {
      width: 100%;
      padding: 0;
      & > .row {
        flex-flow: column;
        margin: 0;
        & > .col {
          padding: 0;
          &:first-child {
            flex-basis: auto;
          }
        }
      }
    }
    .LoginSection-title {
      width: 100%;
      padding: 50px 50px 0px;
      // height: 100px;
      h2 {
        font-size: 35px;
        .LoginSection-title-target {
          font-size: 35px;
        }
      }
    }
    .LoginSection-description {
      width: 100%;
      margin-top: 40px;
      margin-left: -5px;
      p {
        opacity: 0.8;
        letter-spacing: -0.4px;
        margin-bottom: 12px;
        &:first-child {
          opacity: 0.9;
        }
      }
    }
    .LoginSection-form {
      width: 100%;
      padding: 0 50px;
      // margin-top: 55px;

      & > .row {
        margin: 0;
      }
      .Loginform-component {
        margin-top: 24px;
        margin-bottom: 66px;
      }
      .LoginButton-component {
        margin-bottom: 92px;
        width: 100%;
      }
    }
    .loginContainer-top-row {
      border-bottom-left-radius: 120px;
      background-color: rgba(241, 241, 241, 0.93);
    }
    .loginContainer-col {
      background-color: transparent !important;
    }
    .link-info {
      padding-bottom: 10px;
      padding-right: 0px;
      padding-left: 0px;
      .col {
        font-size: 14px;
      }
    }
    .link-loginForm {
      margin-top: 18px;
      margin-right: 0px;
      font-size: 16px;
      color: #000000;

      font-weight: bold;
    }
    .link-with-margin {
      margin-left: 50%;
    }
    .row-with-width {
      margin-left: 5px;
      max-width: 110%;
    }
    .bottom-empty-space {
      margin-bottom: 50px;
      .float-right {
        max-width: 90px;
      }
    }
    .col-with-width {
      max-width: 48%;
    }
    .link-with-margin-right {
      margin-right: 15px;
    }
    .login-button-form {
      width: 100%;
    }
    .nonmemberReservation {
      width: 100%;
      max-width: none;
      height: 64px;
      text-align: left;
      line-height: 50px;
      border-radius: 20px;
      font-size: 20px;
      font-weight: bold;
      display: inline-flex;

      flex-wrap: wrap;
      margin-bottom: 16px;
      .material-icons.md-48 {
        font-size: 30px;
        margin-top: 11px;
        position: relative;
      }
      .button-text-align {
        margin-left: 3%;
        font-size: 20px;
        margin-bottom: 0px;
        min-width: 150px;
      }
    }
    .nonmemberHistory {
      width: 100%;
      max-width: none;
      height: 64px;
      text-align: center;
      line-height: 55px;
      border-radius: 20px;
      font-size: 20px;
      font-weight: bold;

      margin-bottom: 16px;
      .material-icons.md-48 {
        font-size: 30px;
        margin-top: 11px;
        position: relative;
      }
      .button-text-align {
        margin-left: 3%;
        font-size: 20px;
        margin-bottom: 0px;
        min-width: 150px;
      }
    }
    .backLoginButtonForm {
      padding-left: 0px;
    }
  }
}

// @media only screen and (max-width: 500px) {
//   #LoginSection {
//     .nonmemberReservation {
//       .material-icons.md-48 {
//         margin-left: 20%;
//       }
//     }
//     .nonmemberHistory {
//       .material-icons.md-48 {
//         margin-left: 20%;
//       }
//     }
//   }
// }
