.ShareModal {
  .modal-content {
    min-height: 222px;
    width: 441px;
    border: none;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22), 0 7px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .modal-header {
    border: none;
    padding: 30px;
    .modal-title {
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.2px;
    }
    .close {
      position: relative;
      right: -4px;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 0;
      outline: 0;
      opacity: 1;
      background: url('../../icons/ic-close-modal.svg') no-repeat center;
      span {
        display: none;
      }
    }
  }
  .modal-body {
    padding: 0px 30px;
    .share-detail {
      background-color: #f5f8fa;
      padding: 5px 15px;
      .detail-item {
        margin: 10px 0px;
        span,
        a {
          color: #2c3238;
          font-size: 13px;
          font-weight: 700;
          letter-spacing: 0.15px;
          line-height: 20px;
        }
        a.detail-url {
          margin-left: 12px;
          font-size: 11px;
          font-weight: 500;
          color: #26b9d1;
          display: none;
        }
        &:hover {
          .detail-url {
            display: inline;
          }
        }
        .ic-trash {
          float: right;
          cursor: pointer;
        }
      }
    }

    p {
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05px;
      line-height: 19px;

      &.subtitle {
        font-weight: 700;
      }
    }

    textarea {
      margin-bottom: 15px;
      padding: 10px;
      resize: none;
      border: 1px solid #bec9d4;
      border-radius: 3px;
      color: #2c3238;
      font-size: 13px;
      letter-spacing: 0.1px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .modal-footer {
    justify-content: space-between;
    padding: 0 30px 35px;
    border: none;

    .share-info {
      color: #56606b;
      font-size: 11px;
      letter-spacing: 0.15px;
      line-height: 17px;

      .count {
        font-weight: 700;
      }
    }

    .btn-primary {
      display: flex;
      align-items: center;
      height: 36px;
      padding: 0 12px 0 5px;
      margin: 0;
      border-radius: 3px;
      border: none;
      background-color: #424952;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.2px;
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        border-color: #424952;
        background-color: #424952;
      }
      svg {
        margin-right: 2px;
        fill: #ffffff;
        transform: scale(0.7);
      }
      &:hover {
        background-color: #6b7682;
      }
      &:active,
      &:focus {
        background-color: #2c3238;
      }
      &:disabled {
        background-color: #424952;
        opacity: 0.4;
      }
    }
  }
}
