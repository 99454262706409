@import '../../Mixin';
@import './var';

.search-left-text {
  color: #8996A3;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 22px;
  @include padding(bottom,15px);
  color:#2C3238;
}
.GoogleSearchPage {
  .googleSearch {
    width: 170px;
    @include padding(left, 120px);
    background-image: url('../../icons/google_text.svg');
    @include background(24px 3px);
    background-size: 90px;
  }
}
#googleNoData {
  .googleSearch {
    @include padding(left, 120px);
  }
}