@import '../../../Mixin';

#moduleContainer {
  .cardA {
    flex: 1 1 auto;
    overflow: hidden;
    margin-top: -35px;
    .module-header {
      display: flex;
      flex-flow: row;
      align-items: center;
      position: relative;
      top: 0;
      height: 38px;
      padding-right: 90px;
      margin-bottom: 30px;
      background: none;
      h5 {
        font-family: "Noto Sans KR";
        font-weight: 700;
        margin: 0;
      }
      .logo {
        max-width: 100%;
        max-height: 100%;
        visibility: hidden;
      }
      .btn-group {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-left: auto;
        background: none;
        .btn {
          height: 26px;
          padding: 0 10px;
          border: 1px solid #BEC9D4;
          border-radius: 13px !important;
          background-color: #FFFFFF;
          color: #56606B;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.1px;
          line-height: 17px;
          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled).active {
            border-color: #ED3C73;
            background-color: #FFFFFF;
            color: #E0205C;
          }
          &+.btn {
            margin-left: 4px;
          }
        }
      }
      .selectYear {
        position: absolute;
        top: 0;
        right: 0;
        width: 64px;
        height: 38px;
        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 2px);
          right: 6px;
          display: inline-block;
          width: 0;
          height: 0;
          border-left: 3.5px solid transparent;
          border-right: 3.5px solid transparent;
          border-top: 4px solid #2C3238;
        }
        select {
          padding: 0 6px;
          border: none;
          background: none;
          appearance: none;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.2px;
          line-height: 22px;
          &:focus {
            background: none;
            border: none;
            box-shadow: none;
          }
          &::-ms-expand {
            display: none;
          }
        }
      }
    }
    .module-body {
      padding: 0;
      background: transparent;
      .topic {
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 56px;
        padding: 0 22px;
        margin-bottom: 26px;
        background-color: #FFFFFF;
        i {
          flex: 0 0 auto;
          height: 22px;
          padding: 1px 10px;
          border: 1px solid #42C9DE;
          border-radius: 11px;
          color: #26B9D1;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.1px;
          line-height: 18px;
          text-align: center;
          font-style: normal;
        }
        p {
          flex: 1 1 auto;
          margin: 0 0 0 18px;
          color: #000000;
          font-family: "Noto Sans KR";
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.2px;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .chevron-right {
          flex: 0 0 auto;
          box-sizing: border-box;
          position: relative;
          display: inline-block;
          -ms-transform: scale(1);
          transform: scale(1);
          -ms-transform: scale(var(--ggs, 1));
          transform: scale(var(--ggs, 1));
          width: 28px;
          height: 35px;
          color: #5E646C;
          &:before {
            content: "";
            display: block;
            box-sizing: border-box;
            position: relative;
            width: 8px;
            height: 8px;
            border-bottom: 2px solid;
            border-right: 2px solid;
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            left: 12px;
            top: 13px;
          }
        }

      }
      .list-group {
        flex-flow: row;
        flex-wrap: wrap;
        margin: 0 -12px;
        .list-group-item {
          display: flex;
          flex-flow: column;
          flex: 0 0 auto;
          width: 25%;
          min-width: 277px;
          padding: 0 12px 25px;
          background: none;
          border: none;
          .card {
            display: flex;
            flex-flow: column;
            width: 265px;
            height: 300px;
            border: none;
            border-radius: 0;
            background-color: #FFFFFF;
            box-shadow: 0 0 1px 0 rgba(0,0,0,0.11), 0 1px 3px 0 rgba(0,0,0,0.08);
            cursor: pointer;
          }
          .tags {
            position: absolute;
            top: 16px;
            left: 20px;
            display: flex;
            flex-flow: row;
            .badge {
              height: 26px;
              margin-right: 3px;
              border-radius: 3px;
              background-color: #6B7682;
              color: #FFFFFF;
              font-size: 12px;
              font-weight: 700;
              letter-spacing: 0.1px;
              line-height: 18px;
              &.blue {
                background-color: #77A5FF;
              }
            }
          }
          img {
            flex: 0 0 auto;
            width: 100%;
            height: 142px;
            &+.content {
              margin-top: 0;
            }
          }
          .content {
            flex: 1 1 auto;
            display: flex;
            flex-flow: column;
            margin-top: 35px;
            padding: 15px 20px 12px;
            overflow: hidden;
          }
          h6 {
            flex: 0 0 auto;
            height: 22px;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 6px;
            color: #2C3238;
            font-family: "Noto Sans KR";
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.21px;
          }
          pre {
            flex: 1 1 auto;
            margin-bottom: 12px;
            overflow: hidden;
            color: #56606B;
            font-size: 13px;
            letter-spacing: 0.1px;
            line-height: 18px;
            white-space: pre-wrap;
          }
          address {
            margin: 0;
            color: #6B7682;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.1px;
            line-height: 18px;
            span {
              color: #8996A3;
              font-size: 11px;
              letter-spacing: 0.15px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}