.interests {
  max-width: 600px;
  .modal-content {
    width: 600px;
    min-height: 790px;
    border: 1px solid #dae3ed;
    border-radius: 0;
    background-color: #ffffff;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .modal-header {
    display: flex;
    border-bottom: none;
    justify-content: flex-end;
    height: 66px;
    .btn-icon {
      display: flex;
      align-items: center;
      position: relative;
      width: 32px;
      height: 34px;
      padding: 0;
      margin-left: auto;
      border: none;
      border-radius: 50%;
      background: none;
      i {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        &.ic-close {
          background-image: url('../../../../icons/ic-close.svg');
        }
      }
      &.btn-primary {
        &:active {
          background-color: #ffffff;
          border-color: #ffffff;
        }
      }
    }
  }
  .modal-body {
    padding: 6px 60px 0 80px;
    h5 {
      margin-bottom: 12px;
      color: #56606b;
      font-family: 'Noto Sans KR';
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.2px;
      line-height: 25px;
    }
    .input-wrapper {
      display: flex;
      flex-flow: row;
      align-items: center;
      height: 52px;
      margin-bottom: 14px;
      padding: 0 18px 0 0;
      background: url('../../../../icons/ic-search.svg') no-repeat left 15px center, #f5f8fa;
      background-size: 20px;
      .rs-auto-complete {
        flex: 1 1 auto;
        width: auto;
        height: 100%;
        .rs-input {
          height: 100%;
          padding: 0 0 0 48px;
          border: none;
          border-radius: 0;
          background-color: transparent;
          color: #2c3238;
          font-family: 'Noto Sans KR';
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.2px;
          line-height: 25px;
          &::-webkit-input-placeholder {
            color: #bec9d4 !important;
          }
          &:-ms-input-placeholder {
            color: #bec9d4 !important;
          }
          &::placeholder {
            color: #bec9d4 !important;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
      .remove {
        flex: 0 0 auto;
        position: relative;
        width: 20px;
        height: 20px;
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 2px;
          left: 10px;
          display: inline-block;
          width: 1px;
          height: 16px;
          background-color: #8996a3;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
        &.btn-primary {
          background-color: #f5f8fa;
          border-color: #f5f8fa;
          //   &:not(:disabled):not(.disabled):active,
          //   &:not(:disabled):not(.disabled).active {
          //   }
        }
      }
      .seeAll {
        flex: 0 0 auto;
        width: 68px;
        height: 24px;
        padding: 0 10px;
        margin-left: 18px;
        border-radius: 12px;
        border: 1px solid #8996a3;
        background-color: transparent;
        color: #8996a3;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1px;
        line-height: 18px;
        text-align: center;
      }
    }
    .info {
      margin-bottom: 30px;
      color: #56606b;
      font-family: 'Noto Sans KR';
      font-size: 13px;
      letter-spacing: 0.18px;
      line-height: 20px;
    }
    .keyword-list {
      margin-bottom: 68px;
      dl {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        & + dl {
          margin-top: 12px;
        }
      }
      dt {
        min-width: 75px;
        height: 35px;
        padding-top: 5px;
        color: #8996a3;
        font-family: 'Noto Sans KR';
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.16px;
        line-height: 25px;
      }
      dd {
        margin: 0;
        padding-left: 5px;
        color: #8996a3;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        letter-spacing: -0.13px;
        line-height: 33px;
        & > span {
          position: relative;
          display: inline-flex;
          align-items: center;
          height: 30px;
          padding: 0 14px;
          margin: 2.5px 5px 2.5px 0;
          background-color: #424952;
          border-radius: 15px;
          color: #ffffff;
          font-family: 'Noto Sans KR';
          font-size: 13px;
          font-weight: 700;
          letter-spacing: 0.22px;
          line-height: 16px;
          cursor: pointer;
          i,
          span {
            display: none;
          }
          &:hover {
            span {
              position: absolute;
              top: -10px;
              right: -5px;
              display: inline-block;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #6b7682;
              cursor: pointer;
              &:before,
              &:after {
                content: '';
                position: absolute;
                top: 5px;
                left: 8px;
                display: inline-block;
                width: 1px;
                height: 9px;
                background-color: #ffffff;
              }
              &:before {
                transform: rotate(45deg);
              }
              &:after {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
    .customCheckbox {
      display: inline-flex;
      & + label {
        margin-left: 10px;
      }
      input {
        width: 20px;
        height: 20px;
        margin: 0;
        opacity: 0;
        z-index: 1;
      }
      span {
        position: absolute;
        display: inline-flex;
        align-items: center;
        padding-left: 8px;
        color: #56606b;
        font-family: 'Noto Sans KR';
        font-size: 13px;
        letter-spacing: 0.18px;
        line-height: 20px;
        &::before {
          content: '';
          position: relative;
          top: 0;
          left: -8px;
          display: inline-block;
          width: 16px;
          height: 16px;
          border: 1px solid #a5b3c2;
          border-radius: 3px;
          box-sizing: border-box;
        }
      }
      input:checked ~ span {
        &::before {
          background-color: #26b9d1;
          border-color: #26b9d1;
        }
        &::after {
          content: '';
          position: absolute;
          top: 3px;
          left: 5px;
          display: inline-block;
          width: 6px;
          height: 10px;
          border-right: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
          transform: rotate(45deg);
        }
      }
    }
  }
  .modal-footer {
    justify-content: center;
    padding: 0 0 66px;
    border: none;
    .btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
      height: 46px;
      padding: 0 22px 0 5px;
      margin: 0;
      border: none;
      border-radius: 23px;
      background-color: #e0205c;
      color: #ffffff;
      font-family: 'Noto Sans KR';
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.3px;
      line-height: 17px;
      text-align: center;
      svg {
        margin-right: 15px;
        fill: #ffffff;
        transform: scale(1);
      }
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        background-color: #cb0f4a;
      }
      &:hover {
        background-color: #f55d8d;
      }
      &:active,
      &:focus {
        background-color: #cb0f4a;
      }
      &:disabled {
        background-color: #e0205c;
      }
    }
  }
}

.modal.show + .rs-picker-menu {
  width: 458px;
  margin-top: 0;
  border: 1px solid #dae3ed;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  opacity: 1;
  z-index: 9999;
  ul {
    padding: 10px;
    li {
      .rs-auto-complete-item {
        position: relative;
        padding-left: 40px;
        pointer-events: auto;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          left: 18px;
          display: inline-block;
          width: 10px;
          height: 10px;
          background: url('../../../../icons/ic-search-mini.svg') no-repeat center;
        }
        &:hover {
          background-color: #e9eff5;
        }
        .disabled {
          opacity: 0.5;
        }
        div {
          position: relative;
          color: #2c3238;
          font-family: 'Noto Sans KR';
          font-size: 14px;
          letter-spacing: -0.16px;
          line-height: 25px;
          span {
            &:first-child {
              font-weight: 700;
            }
            &.keyword {
              color: #26b9d1;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
