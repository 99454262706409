@import '../../Mixin';

.SearchDetail {
  background-color: #ffffff;
  z-index: 10;
  height: calc(100% - 90px);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22), 0 7px 10px 0 rgba(0, 0, 0, 0.12);
  position: fixed !important;

  &.maximize {
    transform: translate(0px, 0px) !important;
    width: 100% !important;
    height: 100% !important;
    .detail-header {
      cursor: default;
    }
  }
  &.detail-hide {
    display: none !important;
  }
  &.detail-show {
    display: inline-block !important;
  }

  .vscroll {
    height: 100%;
  }

  .detail-header {
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding-bottom: 30px;
    cursor: move;
    justify-content: space-between;

    .detail-header-left {
      @include margin(top, 22px);
      @include margin(left, 60px);
      width: 192px;
      .original-btn-area {
        .original-btn {
          height: 19px;
          width: 65px;
          box-sizing: border-box;
          border-radius: 13px;
          border: 1px solid #42c9de;
          color: #26b9d1;
          font-size: 11px;
          font-weight: 700;
          letter-spacing: 0.1px;
          text-align: center;
          background-color: #ffffff;
          outline: none;
        }
      }
      .full-screen-btn-area {
        margin-left: 26px;
        width: 30px;
        .btn-icon {
          display: flex;
          align-items: center;
          height: 24px;
          padding: 0;
          margin: 0;
          border: none;
          border-radius: 50%;
          background: none;
          i {
            display: inline-block;
            width: 26px;
            height: 15px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &.ic-maximize {
              background-image: url('../../icons/ic-maximize.svg');
            }
            &.ic-minimize {
              background-image: url('../../icons/ic-minimize.svg') !important;
            }
          }
        }
      }
      .bookmark-area {
        @include margin(left, 16px);
        background-image: url('../../icons/bookmark3.svg');
        @include background(0px 4px);
        width: 20px;
        cursor: pointer;
      }
      .share-area {
        @include margin(left, 22px);
        background-image: url('../../icons/share2.svg');
        @include background(0px 4px);
        width: 22px;
      }
    }
    .detail-header-right {
      @include margin(top,19px);
      @include margin(right,50px);
      .comment-area {
        @include margin(left, 55px);
        @include padding(left, 30px);
        @include padding(top, 4px);
        background-image: url('../../icons/comment.svg');
        @include background(7px 6px);
        background-size: 16px 15px;
        color: #424952;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.1px;
        line-height: 18px;
        width: 50px;
      }
      .close-area {
        @include margin(left, 17px);
        background-image: url('../../icons/big_cross.svg');
        @include background(5px 5px);
        width: 32px;
      }
    }
  }
  .detail-body {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: hidden;
    padding: 0 20px 20px 0;
    .detail-body-top {
      width: 100%;
      height: 100%;
      padding: 0 30px 0 60px;
      overflow-y: auto;
      overflow-x: hidden;
      .detail-body-title {
        flex: 0 0 auto;
        min-height: 47px;
        height: auto;
        width: auto;
        color: #2c3238;
        font-family: 'Noto Sans KR';
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.53px;
        line-height: 47px;
      }
      .detail-body-info {
        flex: 0 0 auto;
        @include margin(top, 8px);
        min-height: 24px;
        color: #2c3238;
        font-family: 'Noto Sans KR';
        font-size: 16px;
        letter-spacing: 0.12px;
        line-height: 24px;
        .author {
          font-weight: 700;
        }
      }
      .detail-body-tag-list {
        @include margin(top, 22px);
        .tag {
          &:first-child {
            @include margin(left, 0px);
          }
          @include margin(left, 4px);
          @include padding(left, 8px);
          @include padding(right, 8px);
          @include padding(bottom, 2px);
          border-radius: 3px;
          background-color: #e9eff5;
          height: 18px;
          color: #56606b;
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 18px;
        }
      }
    }
    .detail-body-content {
      flex: 1 1 auto;
      @include margin(top, 50px);
      .file-area {
        .file-info {
          @include padding(top, 11px);
          @include padding(left, 60px);
          @include padding(right, 20px);
          @include padding(bottom, 12px);
          @include background(19px 18px);
          background-image: url('../../icons/pdf.svg');
          box-sizing: border-box;
          border: 1px solid #dae3ed;
          border-radius: 3px;
          background-color: #ffffff;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
          .name {
            height: 20px;
            color: #2c3238;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.05px;
            line-height: 20px;
          }
          .capacity {
            height: 17px;
            color: #8996a3;
            font-size: 11px;
            letter-spacing: 0.15px;
            line-height: 17px;
          }
        }
      }
      .content-area {
        @include padding(top, 20px);
        @include padding(bottom, 40px);
        color: #2c3238;
        font-family: 'Noto Sans KR';
        font-size: 18px;
        letter-spacing: 0.14px;
        line-height: 27px;
        white-space: pre-wrap;
      }
    }
  }
}
