#mainFooter {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  background-color: #f5f8fa;
  ul {
    display: flex;
    flex-flow: row;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      padding: 0 7px;
      .btn,
      span {
        float: left;
        padding: 0;
        background: none;
        border: none;
        color: #56606b;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.1px;
        b {
          color: #2c3238;
          font-weight: 700;
        }
        .dark {
          color: #2c3238;
          font-size: 11px;
          letter-spacing: 0.15px;
        }
        .light {
          color: #8996a3;
          font-size: 11px;
          letter-spacing: 0.15px;
        }
      }
      & + li {
        &:before {
          content: '';
          position: absolute;
          left: -1px;
          top: calc(50% - 1px);
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: #8996a3;
        }
      }
    }
  }
}
