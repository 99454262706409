#Profile {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 306px;
  min-height: 332px;
  padding-top: 64px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22), 0 7px 10px 0 rgba(0, 0, 0, 0.12);
  .avatar {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin-bottom: 24px;
    text-align: center;
    img {
      width: 100%;
      border-radius: 50%;
      background-color: #E9EFF5;
      overflow: hidden;
    }
    &:after {
      content: "";
      position: absolute;
      top: 12px;
      right: -4px;
      display: inline-block;
      height: 22px;
      width: 22px;
      background: url("../../../icons/ic-pencil-mini.svg") no-repeat center, #000000;
      border-radius: 50%;
      box-shadow: 0 0 1px 0 rgba(0,0,0,0.11), 0 1px 3px 0 rgba(0,0,0,0.08);
    }
  }
  .name {
    margin-bottom: 8px;
    color: #2C3238;
    font-family: "Noto Sans KR";
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 25px;
    text-align: center;
    b {
      margin-right: 8px;
      font-weight: 700;
    }
  }
  .department {
    margin-top: 0;
    margin-bottom: 38px;
    color: #56606B;
    font-family: "Noto Sans KR";
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 18px;
    text-align: center;
  }
  .menu {
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 259px;
    padding: 15px 0;
    margin: 0 0 6px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 259px;
      border-top: 1px solid #BEC9D4;
      opacity: 0.3;
    }
    li {
      position: relative;
      a {
        padding: 5px;
        color: #8996A3;
        font-family: "Noto Sans KR";
        font-size: 11px;
        letter-spacing: 0.15px;
        line-height: 17px;
      }
      &+li {
        margin-left: 8px;
        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 0px);
          left: -6px;
          display: inline-block;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: #8996A3;
        }
      }
    }
  }
}