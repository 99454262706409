#ShareMemberSection {
  &.selWrap {
    &.userList-visible {
      .css-26l3qy-menu {
        display: inline;
      }
    }
    &.userList-invisible {
      .css-26l3qy-menu {
        display: none;
      }
    }
  }
  .search-member-select {
    [class$="-ValueContainer"] {
      padding-left: 30px;
      background: url("../../../icons/ic-search-mini.svg") no-repeat left 12px center;
    }
    [class$="-control"] {
      height: 37px;
      border: 1px solid #BEC9D4;
      border-radius: 3px;
      background-color: #FFFFFF;
      box-shadow: none;
    }
    [class$="-placeholder"] {
      margin: 0;
      color: #8996A3;
      font-size: 12px;
      letter-spacing: 0.1px;
      line-height: 18px;
    }
    [class$="-Input"] {
      margin: 0;
      color: #2C3238;
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 19px;
    }
    [class$="-IndicatorsContainer"] {
      display: none;
    }
    [class$="-menu"] {
      margin-top: -1px;
      border-radius: 5px;
      background-color: #FFFFFF;
      box-shadow: 0 0 2px 0 rgba(0,0,0,0.22), 0 7px 10px 0 rgba(0,0,0,0.12);
      [class$="-MenuList"] {
        height: auto;
        max-height: 350px;
      }
      [class$="-option"] {
        min-height: 40px;
        padding-left: 11px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .selected-member-group{
    margin-top: 4px;
    .selected-member{
      padding-left: 4px;
      margin-right: 0px;
      margin-left: 7px;
      margin-top: 4px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      border-radius: 3px;
    }
  }
  .rs-tag-text {
    display: inline-flex;
  }
  .rs-tag-icon-close {
    position: absolute;
    top: 5px;
    right: 7px;
    font-style: normal;
    font-family: 'rsuite-icon-font';
    font-size: 10px;
    cursor: pointer;
  }
}

.member-tooltip{
  z-index: 99999;
  div{
    color: #FFFFFF;
    font-family: "Noto Sans CJK KR";
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 18px;
    span:first-child{
      margin-right: 6px;
    }
  }
  .arrow {
    &::before {
      border-top-color: #6B7682;
    }
  }
  .tooltip-inner {
    height: 34px;
    padding: 7px 12px;
    background-color: #6B7682;
  }
}