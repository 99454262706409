.navBar {
  span {
    margin-right: 5px;
    font-size: 16px;
  }
  .bold {
    font-weight: bold;
    font-size: 18px;
  }
  .navbar-expand {
    span {
      color: rgba(#ffffff, 0.8);
      font-size: 14px;
    }
    .mainMenu {
      span {
        margin-right: 70px;
        cursor: pointer;
      }
    }
  }
}
