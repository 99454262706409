@import '../../Mixin.scss';

.SearchPage {
  position: relative;
  padding-top: 90px;
  padding-bottom: 198px;
  background-color: #f5f8fa;
  flex: 1 1 auto;
  .contents-wrap {
    @include margin(top,66px);
    @include margin(bottom,66px);
  }
}

@media all and (-ms-high-contrast:none) {
  *::-ms-backdrop, .MiContainer .SearchPage {
    padding-top: 90px;
  }
}
