@import '../../Mixin';

.SearchBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 198px;
  @include padding(left,267px);
  @include padding(top,44px);
  background-color:#E9EFF5;
  .like-text {
    @include margin(left,18px);
    @include padding(left,23px);
    color: #2C3238;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 19px;
  }
  .bottom-wrap {
    .title-area {
      .title {
        width: 187px;
        height: auto;
        @include margin(bottom,0);
        color: #6B7682;
        font-family: "Noto Sans KR";
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 22px;
      }
      .like {
        @extend .like-text;
        background-image: url('../../icons/like.svg');
        @include background(0px 3px);
      }
      .unlike {
        cursor: pointer;
        @extend .like-text;
        background-image: url('../../icons/unlike.svg');
        @include background(0px 3px);
      }
    }
    .input-area {
      @include padding(top, 19px);
      .input {
        width: 375px;
        height: 52px;
        padding: 0 12px 0;
        border: 0;
        font-family: "Noto Sans KR";
        &:focus {
          outline: none;
        }
      }
      .search-function {
        width: 174px;
        height: 52px;
        background: #ffffff;
        .search-cancel-btn {
          @include margin(right, 20px);
          width: 17px;
          background-image: url('../../icons/cross.svg');
          @include background(0px 20px);
        }
        .search-btn {
          width: 20px;
          background-image: url('../../icons/search_btn.svg');
          @include background(0px 15px);
        }
        .rectangle {
          @include margin(top, 8px);
          @include margin(left, 22px);
          height: 36px;
          width: 1px;
          background-color: #DAE3ED;
        }
        .google {
          @include margin(top, 13px);
          @include margin(left, 25px);
          width: 24px;
          background-image: url('../../icons/google_logo.svg');
          @include background(0px 0px);
        }
        .search-next-btn {
          @include margin(left, 18px);
          width: 20px;
          background-image: url('../../icons/next.svg');
          @include background(0px 20px);
        }
      }
    }
  }
}
