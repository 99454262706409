.ContentsCard {
  &.card {
    width: 250px;
    height: 350px;
    flex: none;
    border-radius: 3px;
    border: none;
    background-color: #FFFFFF;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.11), 0 1px 3px 0 rgba(0,0,0,0.08);
  }

  .cardTitle{
    font-weight: bold;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .cardText{
    max-height: 3.1rem;
    text-overflow: ellipsis;
    line-height: 1.1;
    overflow: hidden;
  }
  .publishInfo{
    margin-top: 10px;
    color: #6d7073;
    position: absolute;
    bottom: 10px;
  }
  .publisher{
    font-weight: bold;
    font-size: 0.85rem;
  }
  .publishedDate{
    margin-left: 15px;
    font-size: 0.75rem;
  }
}


#ContentsCard.type1{
  .cardImg {
    width: 100%;
    height: 170px;
  }
}


#ContentsCard.type2{
  width: 500px;
  color: white;

  .card-body{
    position: absolute;
    bottom: 0;
  }
  .cardImg {
    width: 100%;
    height: 350px;
  }
  .publishInfo{
    color: white;
  }
}
