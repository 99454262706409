.LocalNav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  min-height: 90px;
  background: #FFF;
  border-bottom: 1px solid #DAE3ED;
  .nav-list,
  .nav-sublist {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 30px;
    padding: 0;
    margin-bottom: 0;
    li {
      position: relative;
      padding: 0 5px 5px;
      margin: 0 6px;
      color: #424952;
      font-family: "Noto Sans KR";
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.2px;
      text-align: center;
      list-style: none;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 0%;
        height: 2px;
        bottom: -2px;
        background: #2C3238;
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
        background: #2C3238;
        transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
      }
      &:hover:before {
        background: #2C3238;
        width: 100%;
        transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
      }
      &:hover:after {
        background: transparent;
        width: 100%;
        transition: 0s;
      }
      &.select {
        margin-bottom: -2px;
        border-bottom: 2px solid #2C3238;
      }
      &.home {
        color: #E0205C;
        &.select {
          border-bottom: 2px solid #E0205C;
        }
        &:before,
        &:after,
        &:hover:before {
          background: #E0205C;
        }
        &:hover:after {
          background: transparent;
        }
      }
    }
  }
  .nav-sublist {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #DAE3ED;
    li {
      color: #E0205C;
    }
  }
  #widgetSetting {
    position: absolute;
    right: 100px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90px;
    padding: 0 12px;
    border-radius: 3px;
    border: none;
    background-color: #F5F8FA;
    color: #6B7682;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.1px;
    text-align: center;
    &:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url("../../../icons/ic-cog.svg") no-repeat center;
    }
  }
}
