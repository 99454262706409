@import '../../../Mixin';

.leftMenu {
  width: 178px;
  min-width: 178px;
  margin-right: 112px;
  .list-group {
    &+.list-group {
      margin-top: 28px;
    }
    h4 {
      margin-bottom: 10px;
      color: #A5B3C2;
      font-family: "Noto Sans KR";
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.1px;
      line-height: 18px;
    }
    .list-group-item {
      height: 22px;
      padding: 0;
      background: none;
      border: none;
      color: #424952;
      font-family: "Noto Sans KR";
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.2px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:active,
      &.active {
        color: #26B9D1;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        color: #26B9D1;
      }
      &+.list-group-item {
        margin-top: 8px;
      }
    }
  }
}

.module-title {
  &+section {
    .leftMenu {
      margin-top: 54px;
    }
  }
}