@mixin border($direction,$size,$color) {
  border-#{$direction}: $size solid $color;
}
@mixin margin($direction,$size) {
  margin-#{$direction}: $size;
}
@mixin padding($direction,$size) {
  padding-#{$direction}: $size;
}
@mixin background($position:0 0, $repeat:no-repeat) {
  background: {
    position: $position;
    repeat: $repeat;
  }
}
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}

@mixin animation ($animation, $duration, $timing, $delay) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-timing-function: $timing;
  -webkit-animation-fill-mode: forwards;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-timing-function: $timing;
  -moz-animation-fill-mode: forwards;

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-timing-function: $timing;
  -o-animation-fill-mode: forwards;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-timing-function: $timing;
  animation-fill-mode: forwards;
}

@mixin keyframes ($animation) {
  @-webkit-keyframes #{$animation} {
    @content;
  }
  @-moz-keyframes #{$animation} {
    @content;
  }
  @-o-keyframes #{$animation} {
    @content;
  }
  @keyframes #{$animation} {
    @content;
  }
}