@import '../../Mixin';
@import './var';

.SearchRight {
  .boarder-area {
    @include padding(left, 45px);
  }
  .card {
    width: 300px;
    padding: 18px 20px;
    border: 1px solid #dae3ed;
    border-radius: 3px;
    & > .vbox {
      position: relative;
      & + .vbox {
        @include margin(top, 20px);
        @include padding(top, 18px);
        &:before {
          content: '';
          @include position(absolute, 0, -20px, auto, -20px);
          display: inline-block;
          width: 298px;
          height: 1px;
          background-color: #dae3ed;
        }
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    color: #424952;
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.2px;
    line-height: 22px;
    span {
      @include margin(left, 5px);
      font-weight: 400;
      line-height: 21px;
    }
  }
  .word-list {
    @include margin(top, 12px);
    @include margin(bottom, -8px);
    @include margin(left, -10px);
    @include margin(right, -5px);
    width: 270px;
  }
  .word {
    @include margin(left, 5px);
    @include margin(right, 5px);
    @include margin(bottom, 8px);
    cursor: pointer;
    padding: 7.85px 10px 9px 10px;
    background: #f5f8fa;
    box-sizing: border-box;
    border: 1px solid #f5f8fa;
    border-radius: 13px;
    color: #56606b;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 17px;
    &:hover {
      border-color: #bec9d4;
    }
  }
  .avatar {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    &.bg-grey {
      background-color: $avatar_bg_grey;
    }
    &.bg-red {
      background-color: $avatar_bg_red;
    }
    &.bg-darkgrey {
      background-color: $avatar_bg_darkgrey;
    }
    &.bg-green {
      background-color: $avatar_bg_green;
    }
    &.bg-blue {
      background-color: $avatar_bg_blue;
    }
    &.bg-black {
      background-color: $avatar_bg_black;
    }
    &.bg-yellow {
      background-color: $avatar_bg_yellow;
    }
    &.bg-orange {
      background-color: $avatar_bg_orange;
    }
    &.bg-purple {
      background-color: $avatar_bg_purple;
    }
  }
  .classification {
    .classification-title {
      @extend .title;
    }
    .classification-result {
      @include margin(top, 18px);
      dl {
        display: flex;
        align-items: center;
        @include margin(bottom, 0);
        & + dl {
          @include margin(top, 10px);
        }
      }
      dt {
        width: 70px;
        color: #8996a3;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.13px;
        line-height: 25px;
      }
      dd {
        margin: 0;
        color: #424952;
        font-size: 12px;
        letter-spacing: -0.13px;
        line-height: 25px;
        &.matched {
          color: #26b9d1;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: -0.16px;
        }
        &.empty {
          color: #a5b3c2;
          font-size: 12px;
          letter-spacing: -0.13px;
          line-height: 25px;
        }
      }
    }
  }
  .recommend {
    .recommend-title {
      @extend .title;
      @include margin(bottom, 10px);
      font-size: 12px;
      letter-spacing: -0.13px;
      line-height: 18px;
      .recommend-switch {
        position: absolute;
        top: 18px;
        right: 0;
        .custom-switch {
          padding: 0;
          min-height: 0;
          .custom-control-input {
            width: 0;
            height: 0;
            &:checked ~ .custom-control-label {
              &::before {
                color: #fff;
                border-color: #e0205c;
                background-color: #e0205c;
              }
              &::after {
                background-color: #ffffff;
                border-color: #e0205c;
                transform: translateX(14px);
              }
            }
          }
          .custom-control-label {
            cursor: pointer;
            &::before {
              left: -32px;
              top: 4px;
              width: 32px;
              height: 10px;
              box-shadow: none;
              background-color: #adb5bd;
              border-radius: 5px;
              border: none;
            }
            &::after {
              top: 0px;
              left: -32px;
              width: 18px;
              height: 18px;
              background-color: #fff;
              border-radius: 50%;
              border: 2px solid #adb5bd;
              transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
          }
        }
      }
    }
    .recommend-info {
      color: #a5b3c2;
      font-size: 12px;
      letter-spacing: -0.13px;
      line-height: 20px;
    }
  }
  .analysis {
    @include margin(top, 20px);
    @include margin(bottom, 20px);
    @include padding(left, 20px);
    width: 300px;
    height: 46px;
    border-radius: 4px;
    background: #26b9d1;
    align-items: center;
    cursor: pointer;
    .analysis-tag {
      margin: 8px 0 8px 9px;
      padding: 4px 9px 5px 9px;
      border-radius: 5px;
      color: #56606b;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.1px;
      line-height: 17px;
      background: #ffffff;
    }
    .analysis-btn {
      color: #ffffff;
      font-family: "Noto Sans KR";
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.12px;
      line-height: 24px;
    }
    .analysis-link {
      position: absolute;
      right: 14px;
      width: 16px;
      height: 16px;
      background-image: url('../../icons/white_link.svg');
      @include background(0 0);
      background-size: contain;
    }
  }
  .related {
    .related-title {
      @extend .title;
    }
    .related-word-list {
      @extend .word-list;
    }
    .related-word {
      @extend .word;
    }
  }
  .popular {
    .popular-title {
      @extend .title;
    }
    .popular-word-list {
      @extend .word-list;
    }
    .popular-word {
      @extend .word;
    }
    .popular-word-rank {
      @include margin(right, 6px);
      color: #26b9d1;
    }
  }
  .employee-list-area {
    @include margin(top, 20px);
    width: 300px;
    .title {
      color: #424952;
      font-size: 15px;
      letter-spacing: -0.2px;
      line-height: 22px;
    }
    .employee-list {
      @include margin(top, 12px);
      .avatar-1 {
        @extend .avatar;
        background-image: url('../../icons/avatar_1.png');
        @include background(center);
      }
      .avatar-2 {
        @extend .avatar;
        background-image: url('../../icons/avatar_2.png');
        @include background(center);
      }
      .avatar-3 {
        @extend .avatar;
        background-image: url('../../icons/avatar_3.png');
        @include background(center);
      }
      .avatar-4 {
        @extend .avatar;
        background-image: url('../../icons/avatar_4.png');
        @include background(center);
      }
      .employee {
        align-items: center;
        cursor: pointer;
        &:hover {
          margin: 0 -10px 0;
          padding: 0 10px 0;
          background: #f5f8fa;
        }
      }
      .info {
        @include margin(top, 10px);
        @include margin(left, 11px);
        @include margin(bottom, 11px);
        .nickname {
          width: 150px;
          color: #000000;
          font-size: 13px;
          letter-spacing: 0.1px;
        }
        .active-info {
          height: 17px;
          width: 150px;
          color: #8996a3;
          font-size: 11px;
          letter-spacing: 0.15px;
        }
      }
    }
  }
}
