@import '../../../Mixin';

.module-title {
  position: relative;
  a {
    box-sizing: border-box;
    position: relative;
    display: block;
    -ms-transform: scale(1);
    transform: scale(1);
    -ms-transform: scale(var(--ggs, 1));
    transform: scale(var(--ggs, 1));
    width: 35px;
    height: 35px;
    color: #88929B;
    @include position(absolute, 0, auto, auto, -38px);
    &:hover {
      color: #88929B;
    }
    &:before {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 11px;
      height: 11px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      -ms-transform: rotate(-45deg);
      transform: rotate(135deg);
      left: 12px;
      top: 12px;
    }
  }
  h3 {
    margin: 0;
    color: #56606B;
    font-family: "Noto Sans KR";
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 33px;
  }
}

#moduleContainer {
  .title {
    height: 35px;
    @include margin(bottom, 50px);
  }
}