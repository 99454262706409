.DocumentListSection {
  width: 100%;
  #documents {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
    box-shadow: none;
    .rs-checkbox-group {
      flex: 1 1 auto;
      overflow-y: auto;
    }
    .rs-list-item {
      flex: 0 0 auto;
      display: flex;
      width: 100%;
      min-height: 52px;
      padding: 0;
      background-color: transparent;
      border-bottom: 1px solid #DAE3ED;
      box-shadow: none;
      &:hover {
        background-color: #F5F8FA;
        cursor: pointer;
      }
      .rs-list-item-content {
        display: flex;
        width: 100%;
        .rs-checkbox {
          margin-right: 4px;
          outline: none;
          .rs-checkbox-checker {
            top: 7px;
            padding-left: 34px;
          }
          .rs-checkbox-wrapper .rs-checkbox-inner::before {
            border: 1px solid #A5B3C2;
          }
          .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
            border-color: #E0205C; 
          }
          &.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
          &.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
            border-color: #E0205C;
            background-color: #E0205C;
          }
        }
        a {
          margin-right: 14px;
          color: #56606B;
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 17px;
          text-decoration: none;
          &:active,
          &:focus {
            color: #2C3238;
            font-weight: 700;
          }
        }
        .btn {
          flex: 0 0 auto;
          position: relative;
          top: 11px;
          width: 30px;
          height: 30px;
          margin-left: 10px;
          &.btn-icon {
            position: relative;
            width: 34px;
            height: 34px;
            padding: 0;
            margin: 0 0 0 10px;
            border: none;
            border-radius: 50%;
            background: none;
            img {
              display: inline-block;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
        .text {
          flex: 1 1 auto;
          padding: 16px 0;
          overflow: hidden;
          margin-left: 12px;
        }
        .title {
          margin: 0;
          color: #000000;
          font-size: 13px;
          letter-spacing: 0.1px;
          line-height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .comment {
          margin: 4px 0 0;
          color: #A5B3C2;
          font-size: 12px;
          letter-spacing: -0.13px;
          line-height: 18px;
        }
        .shared {
          flex: 0 0 auto;
          width: 36px;
          height: 16px;
          position: relative;
          top: 17px;
          padding-left: 16px;
          margin: 0 6px 0 8px;
          color: #8996A3;
          font-size: 10px;
          letter-spacing: 0.14px;
          line-height: 17px;
          &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            width: 11px;
            height: 12px;
            background: url("../../../icons/ic-share-mini.svg") no-repeat center;
          }
        }
        .date {
          flex: 0 0 auto;
          position: relative;
          top: 16px;
          width: 68px;
          height: 20px;
          color: #56606B;
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 18px;
        }
        .dropdown {
          flex: 0 0 auto;
          width: 30px;
          margin-left: 2px;
          &:focus {
            outline: none;
          }
          &:active {
            box-shadow: none;
          }
          .dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            top: 15px;
            width: 20px;
            height: 20px;
            padding: 0;
            border-radius: 0;
            border: none;
            background-color: transparent;
            &:after {
              display: none;
            }
            &:focus {
              box-shadow: none;
            }
            i {
              position: relative;
              display: inline-block;
              width: 2px;
              height: 2px;
              background-color: #6B7682;
              &:before,
              &:after {
                content: "";
                position: absolute;
                left: 0;
                display: inline-block;
                width: 2px;
                height: 2px;
                background-color: #6B7682;
              }
              &:before {
                top: -5px;
              }
              &:after {
                bottom: -5px;
              }
            }
          }
          .dropdown-menu {
            width: 140px;
            min-width: auto;
            padding: 10px 0;
            border-radius: 3px;
            border: none;
            background-color: #FFFFFF;
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.17), 0 4px 5px 0 rgba(0,0,0,0.11);
            .dropdown-item {
              height: 30px;
              line-height: 30px;
              padding: 0 16px;
              background-color: transparent;
              color: #424952;
              font-size: 12px;
              letter-spacing: 0.1px;
              &:hover {
                background-color: #F5F8FA;
              }
            }
          }
        }
      }
    }
    &>.rs-list-item {
      margin-top: 18px;
      min-height: 42px;
      &:hover {
        background-color: transparent;
        cursor: default;
      }
      .rs-list-item-content {
        width: calc(100% - 115px);
        align-items: center;
        .rs-checkbox {
          .rs-checkbox-checker {
            top: auto;
          }
        }
      }
    }
  }
  .noDataContent {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin: 104px;
    align-items: center;
    color: #8996a3;
    font-family: 'Noto Sans KR';
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.27px;
    text-align: center;
    svg {
      margin-bottom: 48px;
    }
  }
}
