
#BookmarkModal{
  .modal-content {
    height: 222px;
    width: 441px;
    border: none;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.22), 0 7px 10px 0 rgba(0,0,0,0.12);
  }
  .modal-header{
    border: none;
    padding: 22px 30px;
    .modal-title {
      color: #000000;
      font-family: "Noto Sans KR";
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.2px;
      line-height: 22px;
    }
    .close {
      position: relative;
      right: -4px;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 0;
      outline: 0;
      opacity: 1;
      background: url('../../icons/ic-close-modal.svg') no-repeat center;
      span {
          display: none;
      }
    }
  }
  .modal-body {
    padding: 20px 30px 26px;
    input {
      width: 100%;
      height: 28px;
      padding: 0 8px;
      border: 1px solid #BEC9D4;
      border-radius: 3px;
      color: #2C3238;
      font-size: 12px;
      letter-spacing: 0.1px;
      &::-webkit-input-placeholder {
          color: #8996A3 !important;
      }
      &:-ms-input-placeholder {
          color: #8996A3 !important;
      }
      &::placeholder {
          color: #8996A3 !important;
      }
      &:focus {
          box-shadow: none;
      }
    }
    .invalid-feedback {
      margin-top: 8px;
      color: #E0205C;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.1px;
    }
    .btn-primary {
      position: absolute;
      right: 30px;
      bottom: 26px;
      display: flex;
      align-items: center;
      height: 36px;
      padding: 0 12px 0 5px;
      margin: 0;
      border-radius: 3px;
      border: none;
      background-color: #424952;
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.2px;
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
          border-color: #424952;
          background-color: #424952;
      }
      svg {
          margin-right: 2px;
          fill: #FFFFFF;
          transform: scale(0.7);
      }
    }
  }  
  .modal-footer {
    padding: 0;
    border: none;
  }
}
  
  