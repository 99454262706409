@import '../../../Mixin';

.competitionLeftMenu {
  width: 258px;
  margin-right: 5px;
  margin-top: 56px;
  margin-left: 55px;
  display: inline-block;
  vertical-align: top;
  .list-group {
    &+.list-group {
      margin-top: 28px;
    }
    h4 {
      margin-bottom: 10px;
      color: #A5B3C2;
      font-family: "Noto Sans KR";
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.1px;
      line-height: 18px;
    }
    .list-group-item {
      height: 22px;
      padding: 0;
      background: none;
      border: none;
      color: #424952;
      font-family: "Noto Sans KR";
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.2px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:active,
      &.active {
        color: #26B9D1;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: transparent;
      }
      &+.list-group-item {
        margin-top: 8px;
      }
      img {
        margin-right: 5px;
      }
    }
  }
}