#BookmarkFolder {
  .list-group {
    margin-top: 16px;
    .list-group-item {
      position: relative;
      display: flex;
      height: 32px;
      line-height: 32px;
      padding: 0 0 0 31px;
      background-color: transparent;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      & + .list-group-item {
        margin-top: 7px;
      }
      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 4px;
        width: 20px;
        height: 20px;
        background: url('../../icons/ic-folder.svg') no-repeat center;
      }
      &.active {
        color: #2c3238;
        background-color: #ffffff;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
        &:before {
          background: url('../../icons/ic-folder-on.svg') no-repeat center;
        }
        .ic-folder {
          use {
            fill: #26b9d1;
          }
        }
      }
      .ic-folder,
      .ic-tableau {
        position: absolute;
        top: 8px;
        left: 7px;
      }
      .ic-tableau {
        width: 16px;
        height: 17px;
      }
      p {
        margin: 0;
        color: #8996a3;
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -0.2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .badge {
        padding: 0 8px 0 10px;
        line-height: 32px;
        color: #26b9d1;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1px;
      }
      .dropdown {
        display: none;
        &.show {
          display: flex;
          position: absolute;
          top: 6px;
          right: 5px;
          &:focus {
            outline: none;
          }
          &:active {
            box-shadow: none;
          }
          .dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            padding: 0;
            border-radius: 0;
            border: none;
            background-color: #e9eff5;
            &:after {
              display: none;
            }
            &:hover {
              background-color: #dae3ed;
            }
            &:focus {
              box-shadow: none;
            }
            i {
              position: relative;
              display: inline-block;
              width: 2px;
              height: 2px;
              background-color: #6b7682;
              &:before,
              &:after {
                content: '';
                position: absolute;
                left: 0;
                display: inline-block;
                width: 2px;
                height: 2px;
                background-color: #6b7682;
              }
              &:before {
                top: -5px;
              }
              &:after {
                bottom: -5px;
              }
            }
          }
          .dropdown-menu {
            width: 140px;
            min-width: auto;
            height: 140px;
            padding: 10px 0;
            border-radius: 3px;
            border: none;
            background-color: #ffffff;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.17), 0 4px 5px 0 rgba(0, 0, 0, 0.11);
            .dropdown-item {
              height: 30px;
              line-height: 30px;
              padding: 0 16px;
              background-color: transparent;
              color: #424952;
              font-family: 'Noto Sans KR';
              font-size: 12px;
              letter-spacing: 0.1px;
              &.delete {
                color: #f94b50;
              }
              &:hover {
                background-color: #f5f8fa;
              }
            }
          }
        }
      }
      &:hover {
        background-color: #e9eff5;
        .dropdown {
          display: flex;
          position: absolute;
          top: 6px;
          right: 5px;
          .dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            padding: 0;
            border-radius: 0;
            border: none;
            background-color: #e9eff5;
            &:after {
              display: none;
            }
            &:hover {
              background-color: #dae3ed;
            }
            i {
              position: relative;
              display: inline-block;
              width: 2px;
              height: 2px;
              background-color: #6b7682;
              &:before,
              &:after {
                content: '';
                position: absolute;
                left: 0;
                display: inline-block;
                width: 2px;
                height: 2px;
                background-color: #6b7682;
              }
              &:before {
                top: -5px;
              }
              &:after {
                bottom: -5px;
              }
            }
          }
        }
      }
    }
  }
}
