@import '../../../Mixin';

.Unlike {
  .modal-content {
    width: 518px;
    border: none;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.22), 0 7px 10px 0 rgba(0,0,0,0.12);
  }
  .modal-header {
    @include padding(top,22px);
    @include padding(left,24px);
    @include padding(right,24px);
    border: none;
    .modal-title {
      height: 25px;
      color: #2C3238;
      font-family: "Noto Sans KR";
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.2px;
    }
    .close {
      position: relative;
      right: -4px;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 0;
      outline: 0;
      opacity: 1;
      background: url('../../../icons/modal_close.svg') no-repeat center;
      span {
        display: none;
      }
    }
  }
  .modal-body {
    @include padding(top, 6px);
    @include padding(left, 24px);
    @include padding(right, 24px);
    .unlike-content {
      @include padding(top, 10px);
      @include padding(right, 10px);
      @include padding(bottom, 10px);
      @include padding(left, 10px);
      height: 343px;
      border: 1px solid #BEC9D4;
      border-radius: 3px;
      .unlike-textarea {
        width: 100%;
        height: 100%;
        padding: 0;
        color: #8996A3;
        font-size: 13px;
        letter-spacing: 0.1px;
        line-height: 19px;
        border: none;
        resize: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .modal-footer {
    border-top: 0;
    @include padding(top, 5px);
    @include padding(right, 19px);
    @include padding(bottom, 23px);
    .send-btn {
      @include padding(left,25px);
      border-radius: 3px;
      height: 36px;
      width: 76px;
      background-color: #424952;
      border-color: #424952;
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.2px;
      background-image: url('../../../icons/send.svg');
      @include background(5px 7px);
    }
  }
}