.AddUrl {
    .modal-content {
        //height: 222px;
        width: 441px;
        border: none;
        border-radius: 3px;
        background-color: #FFFFFF;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.22), 0 7px 10px 0 rgba(0,0,0,0.12);
    }
    .modal-header {
        border: none;
        padding: 22px 30px;
        .modal-title {
            color: #000000;
            font-family: "Noto Sans KR";
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.2px;
        }
        .close {
            position: relative;
            right: -4px;
            width: 25px;
            height: 25px;
            margin: 0;
            padding: 0;
            outline: 0;
            opacity: 1;
            background: url('../../../../icons/ic-close-modal.svg') no-repeat center;
            span {
                display: none;
            }
        }
    }
    .modal-body {
        padding: 26px 30px;
        .form-group {
            display: flex;
            align-items: center;
            margin: 0 0 15px 0;
            &#url-group {
                margin: 0;
            }
        }
        .container {
            padding: 15px 0 0 0;
            border-top: 1px solid #BEC9D4;
        }
        label {
            flex: 0 0 auto;
            width: 64px;
            margin: 0;
            color: #000000;
            font-family: "Noto Sans KR";
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 0.05px;
            line-height: 19px;
        }
        input {
            height: 28px;
            padding: 0 8px;
            border: 1px solid #BEC9D4;
            border-radius: 3px;
            color: #2C3238;
            font-size: 12px;
            letter-spacing: 0.1px;
            &#url {
                width: 60%;
            }
            &#title #description {
                width: 80%;
            }
            &::-webkit-input-placeholder {
                color: #8996A3 !important;
            }
            &:-ms-input-placeholder {
                color: #8996A3 !important;
            }
            &::placeholder {
                color: #8996A3 !important;
            }
            &:focus {
                box-shadow: none;
            }
        }
        select {
            height: 28px;
            padding: 0 8px;
            border: 1px solid #BEC9D4;
            border-radius: 3px;
            color: #2C3238;
            font-family: "Noto Sans KR";
            font-size: 12px;
            letter-spacing: 0.1px;
        }
        .btn-secondary {
            display: flex;
            align-items: center;
            height: 28px;
            padding: 0 12px 0 12px;
            margin: 7px;
            border-radius: 3px;
            border-color: #424952;
            background-color: #FFFFFF;
            color: #424952;
            font-family: "Noto Sans KR";
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.2px;
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                border-color: #424952;
                background-color: #FFFFFF;
            }
            svg {
                margin-right: 2px;
                fill: #FFFFFF;
                transform: scale(0.7);
            }
            &:hover {
                background-color: #FFFFFF;
            }
            &:active,
            &:focus {
                background-color: #FFFFFF;
            }
        }
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
        .invalid-feedback {
            display: flex;
        }
    }
    .modal-footer {
        padding: 0 30px 35px;
        border: none;
        .btn-primary {
            display: flex;
            align-items: center;
            height: 36px;
            padding: 0 12px 0 5px;
            margin: 0;
            border-radius: 3px;
            border: none;
            background-color: #424952;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.2px;
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                border-color: #424952;
                background-color: #424952;
            }
            svg {
                margin-right: 2px;
                fill: #FFFFFF;
                transform: scale(0.7);
            }
            &:hover {
                background-color: #6B7682;
            }
            &:active,
            &:focus {
                background-color: #2C3238;
            }
            &:disabled {
                background-color: #424952;
                opacity: 0.4;
            }
        }
    }
}