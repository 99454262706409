#TermsPage {
  .modal-content {
    width: 700px;
    height: 641px;
    border: 1px solid #DAE3ED;
    border-radius: 0;
    background-color: #FFFFFF;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 45px 55px 55px;
    .terms-modal-header {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      margin-bottom: 35px;
      border-bottom:0px;
      .terms-modal-title {
        color: #2C3238;
        font-family: "Noto Sans KR";
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 25px;
      }
      .revisionDate {
        display: flex;
        align-items: center;
        margin-left: 22px;
        span {
          color: #8996A3;
          font-size: 13px;
          font-weight: 700;
          letter-spacing: -0.1px;
          line-height: 19px;
        }
        select {
          height: 28px;
          width: 120px;
          margin-left: 10px;
          padding: 0 20px 0 12px;
          border: 1px solid #BEC9D4;
          border-radius: 3px;
          background: url("../../icons/ic-triangle-down.svg") no-repeat right 10px center;
          appearance: none;
          color: #8996A3;
          font-size: 11px;
          letter-spacing: 0.05px;
          line-height: 17px;
          &::-ms-expand {
            display: none;
          }
        }
      }
      .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        outline: 0;
        opacity: 1;
        background: url('../../icons/ic-close-modal.svg') no-repeat center;
        span {
          display: none;
        }
      }
    }
    .terms-modal-body {
      padding: 0 10px;
      margin: 0 -10px;
      .terms-content {
        color: #424952;
        font-size: 12px;
        letter-spacing: 0.1px;
        line-height: 18px;
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-button:start:decrement,
      &::-webkit-scrollbar-button:end:increment {
        display: block;
        height: 10px;
        background: transparent;
      }
      &::-webkit-scrollbar-track {
        background: none;
        box-shadow: none;
      }
      &::-webkit-scrollbar-thumb {
        height: 50px;
        width: 50px;
        background: #DAE3ED;
        border-radius: 3px;
      }
    }
    .terms-modal-footer {
      justify-content: center;
      padding: 0;
      margin-top: 30px;
      border: none;
      .terms-modal-footer-btn {
        position: relative;
        height: 46px;
        margin: 0;
        padding: 0 24px 0 62px;
        border-radius: 23px;
        background-color: #E0205C;
        border: none;
        color: #FFFFFF;
        font-family: "Noto Sans KR";
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.3px;
        line-height: 17px;
        &::before{
          content: "」";
          position: absolute;
          top: 15px;
          left: 28px;
          display: inline-block;
          font-size: 28px;
          font-weight: 400;
          transform: rotate(45deg);
        }
        &:hover{
          background-color: #f55d8d;
          border: none;
        }
      }
    }
  }
}
