body {
  margin: 0;
}

.loaderWrap {
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1060;
}
.spinner {
  z-index: 1060;
  width: 40px;
  height: 40px;
  background-color: #a50034;
  border-radius: 10px;
  margin: 100px auto;
  -webkit-animation: lg-rotateplane 1.2s infinite ease-in-out;
  animation: lg-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes lg-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
    background-color: #a50034;
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    background-color: #439099;
  }
}

@keyframes lg-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    background-color: #439099;
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    background-color: #a50034;
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    background-color: #439099;
  }
}
