@import '../../../Mixin';

.ModuleDetail {
  background-color: #ffffff;
  z-index: 10;
  height: calc(100% - 90px);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22), 0 7px 10px 0 rgba(0, 0, 0, 0.12);
  position: fixed !important;

  &.maximize {
    transform: translate(0px, 0px) !important;
    width: 100% !important;
    height: 100% !important;
    .detail-header {
      cursor: default;
    }
  }
  &.detail-hide {
    display: none !important;
  }
  &.detail-show {
    display: inline-block !important;
  }
  .vscroll {
    height: 100%;
  }

  .module-detail-header {
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding-bottom: 30px;
    justify-content: space-between;
    cursor: move;
    .detail-header-top {
      display: flex;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #E9EFF5;
      .list-group {
        flex-flow: row;
        flex: 1 1 auto;
        height: 100%;
        padding-left: 10px;
        .list-group-item {
          display: flex;
          align-items: center;
          padding: 0;
          margin: 0;
          border: none;
          border-radius: 0;
          background: none;
          p {
            position: relative;
            width: 180px;
            height: 32px;
            line-height: 32px;
            padding: 0 47px 0 12px;
            margin: 0;
            color: #56606B;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: -0.1px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            .close {
              position: absolute;
              top: calc(50% - 9px);
              right: 10px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #F5F8FA;
              &:before,
              &:after {
                content: ' ';
                position: absolute;
                top: 4px;
                left: 8px;
                height: 10px;
                width: 2px;
                background-color: #8996A3;
              }
              &:before {
                transform: rotate(45deg);
              }
              &:after {
                transform: rotate(-45deg);
              }
            }
            &:hover {
              border-right-color: transparent;
              border-radius: 5px;
              background-color: #424952;
              color: #FFFFFF;
              .close {
                border-radius: 50%;
                background-color: #56606B;
                &:before,
                &:after {
                  background-color: #FFFFFF;
                }
              }
            }
          }
          &+.list-group-item {
            p {
              border-left: 1px solid #DAE3ED;
            }
          }
          &:last-child {
            p {
              border-right: 1px solid #DAE3ED;
            }
          }
          &:hover {
            &+.list-group-item {
              p {
                border-left-color: transparent;
              }
            }
          }
        }
      }
      .detail-header-top-button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 80px;
        .arrow-right {
          position: relative;
          width: 32px;
          height: 32px;
          background-image: url('../../../icons/ic-arrow-right.svg');
          background-position: center;
          background-repeat: no-repeat;
          &:before {
            content: "";
            position: absolute;
            top: 10px;
            right: 9px;
            display: inline-block;
            width: 1px;
            height: 11px;
            background: #7C8690;
          }
        }
        .close-area {
          width: 32px;
          height: 32px;
          margin: 0 12px 0 4px;
          background-image: url('../../../icons/ic-close-small.svg');
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
 
    .detail-header-left {
      @include margin(top,22px);
      @include margin(left,60px);
      flex: 1 1 auto;
      .original-btn-area {
        .original-btn {
          height: 19px;
          width: 65px;
          box-sizing: border-box;
          border-radius: 13px;
          border: 1px solid #42C9DE;
          color: #26B9D1;
          font-size: 11px;
          font-weight: 700;
          letter-spacing: 0.1px;
          text-align: center;
          background-color: #FFFFFF;
          outline: none;
        }
        .original-btn-atag{
          height: 19px;
          width: 65px;
          color: #26B9D1;
          font-size: 11px;
          font-weight: 700;
          letter-spacing: 0.1px;
          text-align: center;
          background-color: #FFFFFF;
          outline: none;
        }
      }
      .full-screen-btn-area {
        margin-left: 26px;
        width: 30px;
        .btn-icon {
          display: flex;
          align-items: center;
          height: 24px;
          padding: 0;
          margin: 0;
          border: none;
          border-radius: 50%;
          background: none;
          i {
            display: inline-block;
            width: 26px;
            height: 15px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &.module-ic-maximize {
              background-image: url('../../../icons/ic-maximize.svg');
            }
            &.module-ic-minimize {
              background-image: url('../../../icons/ic-minimize.svg') !important;
            }
          }
        }
      }
      .bookmark-area {
        @include margin(left,16px);
        background-image: url('../../../icons/bookmark3.svg');
        @include background(0px 4px);
        width: 12px;
        cursor: pointer;
      }
      .module-share-area {
        @include margin(left,22px);
        background-image: url('../../../icons/share2.svg');
        @include background(0px 4px);
        width: 19px;
      }
    }
    .detail-header-right {
      @include margin(top,19px);
      @include margin(right,50px);
      .comment-area {
        @include margin(left,55px);
        @include padding(left,30px);
        @include padding(top,4px);
        background-image: url('../../../icons/comment.svg');
        @include background(7px 6px);
        background-size: 16px 15px;
        color: #424952;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.1px;
        line-height: 18px;
        width: 50px;
      }
      .module-close-area {
        @include margin(left, 17px);
        background-image: url('../../../icons/big_cross.svg');
        @include background(5px 5px);
        width: 32px;
      }
    }
  }
  .detail-body {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: hidden;
    padding: 0 20px 20px 0;
    .detail-body-top {
      width: 100%;
      height: 100%;
      padding: 0 30px 0 60px;
      overflow-y: auto;
      overflow-x: hidden;
      .detail-body-title {
        flex: 0 0 auto;
        min-height: 47px;
        height: auto;
        width: auto;
        color: #2C3238;
        font-family: "Noto Sans KR";
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.53px;
        line-height: 47px;
      }
      .detail-body-info {
        flex: 0 0 auto;
        @include margin(top, 8px);
        min-height: 24px;
        width: auto;
        color: #2C3238;
        font-family: "Noto Sans KR";
        font-size: 16px;
        letter-spacing: 0.12px;
        line-height: 24px;
        .author {
          font-weight: 700;
        }
      }
      .detail-body-tag-list {
        @include margin(top, 22px);
        .tag {
          &:first-child {
            @include margin(left,0px);
          }
          @include margin(left,4px);
          @include padding(left, 8px);
          @include padding(right, 8px);
          @include padding(bottom, 2px);
          border-radius: 3px;
          background-color: #E9EFF5;
          height: 18px;
          color: #56606B;
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 18px;
        }
      }
    }
    .detail-body-content {
      flex: 1 1 auto;
      @include margin(top, 50px);
      .file-area {
        .file-info {
          @include padding(top, 11px);
          @include padding(left, 60px);
          @include padding(right, 20px);
          @include padding(bottom, 12px);
          @include background(19px 18px);
          background-image: url('../../../icons/pdf.svg');
          box-sizing: border-box;
          border: 1px solid #DAE3ED;
          border-radius: 3px;
          background-color: #FFFFFF;
          box-shadow: 0 1px 3px 0 rgba(0,0,0,0.08);
          .name {
            height: 20px;
            color: #2C3238;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.05px;
            line-height: 20px;
          }
          .capacity {
            height: 17px;
            color: #8996A3;
            font-size: 11px;
            letter-spacing: 0.15px;
            line-height: 17px;
          }
        }
      }
      .content-area {
        @include padding(top, 20px);
        @include padding(bottom, 40px);
        color: #2C3238;
        font-family: "Noto Sans KR";
        font-size: 18px;
        letter-spacing: 0.14px;
        line-height: 27px;
        white-space: pre-wrap;
      }
    }
  }
}

.detail-show {
  .ModuleDetail {
    right: 0;
  }
}