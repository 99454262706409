@import '../../Mixin';
@import './var';

.UnifiedSearchPage {
  &>div+div {
    margin-top: 60px;
  }
  .googleSearch {
    width: 170px;
    @include padding(left, 120px);
    background-image: url('../../icons/google_text.svg');
    @include background(24px 3px);
    background-size: 90px;
  }

  .more {
    @include margin(left, 24px);
    cursor: pointer;
    height: 18px;
    width: 134px;
    color: #8996a3;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 18px;
    background-image: url('../../icons/Triangle.svg');
    @include background(124px 8px);
    &:hover {
      text-decoration: underline;
    }
  }
}
