.competitionContainer {
    display: inline-block;
    width: calc(100% - 320px);
    .title {
        background-color: #424952;
        box-shadow: 0 7px 10px 0 rgba(0,0,0,0.12);
        height: 39px;
        padding-top: 6px;
        padding-bottom: 7px;
        padding-left: 23px;
        span {
            color: #FFFFFF;
            font-weight: bold;
            font-size: 18px;
        }
    }
}