.search-block {
  width: 648px;
  padding: 93px 0 86px;
  margin: 0 auto 0;
  .form-group {
    position: relative;
    margin: 0;
    padding-bottom: 24px;
    border-bottom: 2px solid #6B7682;
    label {
      display: flex;
      align-items: center;
      height: 33px;
      margin-bottom: 22px;
      b {
        margin-right: 8px;
        color: #56606B;
        font-size: 26px;
        font-weight: 700;
      }
      em {
        margin-right: 8px;
        color: #E0205C;
        font-size: 26px;
        font-weight: 700;
        font-style: normal;
      }
      span {
        margin-left: 9px;
        color: #737473;
        font-family: "Noto Sans KR";
        font-size: 21px;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
    input {
      height: 40px;
      line-height: 40px;
      padding: 0 0 0 40px;
      border: none;
      color: #424952;
      caret-color: #E0205C;
      font-family: "Noto Sans KR";
      font-size: 30px;
      font-weight: 700;
      letter-spacing: -0.5px;
      background: url("../../../icons/ic-search.svg") no-repeat left 6px top 10px; 
      &:focus {
        box-shadow: none;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px white inset;
      }
    }
    .advancedSearch {
      position: absolute;
      bottom: 30px;
      right: 0;
      display: flex;
      display: none;
      align-items: center;
      justify-content: center;
      width: 77px;
      height: 24px;
      padding: 0;
      background: none;
      border-radius: 12px;
      border: 1px solid #8996A3;
      color: #8996A3;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.1px;
      i {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 8px;
        height: 8px;
        margin-left: 4px;
        margin-right: -2px;
        &:before,
        &:after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 4px;
          height: 1px;
          background: #8996A3;
        }
        &:before {
          transform: translateX(-1px) rotate(40deg);
        }
        &:after {
          transform: translateX(1px) rotate(140deg);
        }
      }
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        background: none;
        border-color: #8996A3;
        color: #8996A3;
      }
    }
    ::-webkit-input-placeholder {
      /* Edge */
      color: #DAE3ED !important;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #DAE3ED !important;
    }
    ::placeholder {
      color: #DAE3ED !important;
    }
  }
}
