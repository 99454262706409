@import '../../Mixin.scss';

.SearchOption {
  min-height:48px;
  background-color:#FFFFFF;
  @include border(bottom,1px,#DAE3ED);
  .list {
    @include margin(left,105px);
    font-size:12px;
    font-weight: 700;
    color:#424952;
    & > div {
      @include margin(right,30px);
      &:last-child {
        @include margin(right,0);
      }
    }
  }
}

.ordering-triangle {
  width:35px;
  background-image: url('../../icons/Triangle.svg');
  @include background(27px 8px);
}

.date-triangle {
  width:35px;
  background-image: url('../../icons/Triangle.svg');
  @include background(27px 8px)
}

.field-triangle {
  width:37px;
  background-image: url('../../icons/Triangle.svg');
  @include background(27px 8px)
}

.high-grade-triangle {
  width:60px;
  background-image: url('../../icons/Triangle.svg');
  @include background(52px 8px)
}

.option-btn {
  width:85px;
  background-image: url('../../icons/Off.svg');
  @include background(53px 0)
}
