$search_title_fw:700;
$search_title_fs:24px;
$search_title_color:#56606B;
$search_info_pt:6px;
$search_info_pb:10px; // TODO: Merge 확인

$avatar_bg_grey:#D9E3F0;
$avatar_bg_red:#F57373;
$avatar_bg_darkgrey:#697689;
$avatar_bg_green:#67E09C;
$avatar_bg_blue:#2DCCE4;
$avatar_bg_black:#555555;
$avatar_bg_yellow:#FFBF6B;
$avatar_bg_orange:#FF8B66;
$avatar_bg_purple:#BA69C8;
