.MiContainer {
  display: flex;
  flex-flow: column;
  position: relative;
  height: auto;
  min-height: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  &>header {
    flex: 0 0 auto;
    position: absolute;
    top: -400px;
    width: 100%;
    height: 300px;
    background: #fff;
    z-index: 5;
    transition: all 0.3s;
    & > .btn-group {
      position: fixed;
      top: 27px;
      right: 48px;
      height: 34px;
      #systemMap {
        display: none;
      }
      .btn {
        &.btn-icon {
          position: relative;
          width: 34px;
          height: 34px;
          padding: 0;
          margin: 0 0 0 10px;
          border: none;
          border-radius: 50%;
          background: none;
          i {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &.ic-bell {
              background-image: url('../../icons/ic-bell.svg');
            }
            &.ic-bookmark {
              background-image: url('../../icons/ic-bookmark.svg');
            }
            &.ic-pencil {
              background-image: url('../../icons/ic-pencil.svg');
            }
            &.ic-user-circle {
              width: 34px;
              height: 34px;
            }
          }
          img {
            display: inline-block;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
          .badge {
            position: absolute;
            top: 4px;
            right: 8px;
            display: inline-block;
            width: 8px;
            height: 8px;
            padding: 0;
            border: 1px solid #ffffff;
            border-radius: 50%;
            background-color: #e0205c;
          }
        }
      }
    }
  }
  .LocalNav {
    position: fixed;
    top: 0;
    margin-top: 0;
    z-index: 4;
    transition: all 0.3s;
    .search-block {
      position: absolute;
      top: 24px;
      width: 300px;
      left: 54px;
      padding: 0;
      margin: 0;
      background-color: #ffffff;
      transition: all 0.3s;
      z-index: 1;
      .form-group {
        display: flex;
        align-items: center;
        padding: 0;
        border-bottom: none;
        label {
          display: none;
        }
        input {
          display: inline-block;
          width: 248px;
          margin-left: 38px;
          padding-left: 40px;
          padding-right: 0;
          font-size: 20px;
          letter-spacing: -0.33px;
        }
        .advancedSearch {
          display: none;
        }
      }
      .nav-icon {
        display: inline-block;
        width: 14px;
        min-width: 14px;
        &:before,
        &:after,
        div {
          background-color: #56606b;
          content: '';
          display: block;
          height: 1px;
          margin: 4px 0;
          transition: all 0.2s ease-in-out;
        }
      }
      &.searchMode {
        width: calc(100% - 260px);
        .nav-icon {
          &:before {
            background-color: #bec9d4;
            transform: translateY(5px) rotate(135deg);
          }
          &:after {
            background-color: #bec9d4;
            transform: translateY(-5px) rotate(-135deg);
          }
          div {
            background-color: #bec9d4;
            transform: scale(0);
          }
        }
        input {
          width: 100%;
          padding-left: 40px;
        }
      }
    }
    #widgetSetting {
      display: none;
    }
  }
  .tab-content {
    flex: 1 1 auto;
    position: relative;
    display: block;
    padding: 0;
    padding-top: 90px;
    background-color: #f5f8fa;
    transition: all 0.3s;
    z-index: 3;
  }
  &.home {
    header {
      // top: 0;
      top: calc(50% - 240px);
      & > .btn-group {
        #systemMap {
          display: inline-block;
          margin-right: 18px;
          color: #26b9d1;
          font-family: 'Noto Sans KR';
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.2px;
        }
      }
      .search-block {
        .nav-icon {
          display: none;
        }
      }
    }
    .LocalNav {
      // margin-top: 300px;
      margin-top: 0;
      border-bottom: none;
      top: calc(50% + 40px);
      .search-block {
        left: 100px;
        width: 200px;
        .form-group {
          padding: 0;
          border: none;
          label,
          input {
            display: none;
          }
          .nav-icon {
            display: none;
          }
        }
      }
      .nav-list {
        .home {
          display: none;
        }
      }
      #widgetSetting {
        display: flex;
      }
    }
    .tab-content {
      // height: calc(100% - 90px);
      display: none;
    }
    #mainFooter {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0;
      background: none;
    }
  }
}

#forAgreeTerms {
  position: absolute;
  bottom: 60px;
  right: 140px;
  width: 70px;
  padding: 4px 10px;
  font-size: 12px;
  z-index: 1;
}

@media all and (-ms-high-contrast:none) {
  /* IE11 */
  *::-ms-backdrop, .LocalNav {
    position: fixed;
  } 
  *::-ms-backdrop, .MiContainer {
    height: auto;
    min-height: 100%;
  }
  *::-ms-backdrop, .MiContainer .tab-content {
    padding-top: 90px;
  }
  *::-ms-backdrop, .modal-content {
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.22), 0px 7px 10px 0px rgba(0,0,0,0.12) !important;
  } 
}
#popoverProfile {
  max-width: none;
  margin-top: 10px;
  border: none;
  .arrow {
    display: none;
  }
}
