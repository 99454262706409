.Delete {
    .modal-content {
        width: 441px;
        border: none;
        border-radius: 3px;
        background-color: #FFFFFF;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.22), 0 7px 10px 0 rgba(0,0,0,0.12);
    }
    .modal-header {
        border: none;
        padding: 22px 30px 12px;
        .modal-title {
            color: #000000;
            font-family: "Noto Sans KR";
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.2px;
        }
        .close {
            position: relative;
            right: -4px;
            width: 25px;
            height: 25px;
            margin: 0;
            padding: 0;
            outline: 0;
            opacity: 1;
            background: url('../../../../icons/ic-close-modal.svg') no-repeat center;
            span {
                display: none;
            }
        }
    }
    .modal-body {
        padding: 8px 30px 20px;
        pre {
            margin: 0;
            color: #424952;
            font-size: 13px;
            letter-spacing: 0.1px;
        }
    }
    .modal-footer {
        padding: 0 30px 16px;
        border: none;
        .btn {
            display: flex;
            align-items: center;
            height: 36px;
            padding: 0 10px;
            margin: 0;
            border-radius: 3px;
            border: none;
            background-color: transparent;
            color: #E0205C;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.2px;
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                color: #E0205C;
                border-color: transparent;
                background-color: transparent;
            }
            &+.btn {
                margin-left: 24px;
            }
            &:active,
            &:focus {
                background-color: #FEBBD0;
            }
            &:disabled {
                opacity: 0.4;
            }
        }
    }
}