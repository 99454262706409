@import '../../Mixin';


.SearchEmpty {
  //border:1px solid red;
  min-height:370px;
  @include padding(top,50px);
  @include padding(left,188px);
  .result-empty {
    width:265px;
    height:235px;
    color:#8996A3;
    font-size:16px;
    font-weight:700;
    background-image: url('../../icons/search_empty.svg');
    @include background(56px 0);
  }

}
