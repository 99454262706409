#bookmark {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 0;
  & > header {
    display: none;
  }
  header {
    position: relative;
    height: auto;
    top: 0;
    background: transparent;
    padding-bottom: 22px;
    border-bottom: 1px solid #dae3ed;
    margin-bottom: 17px;
    h3 {
      position: relative;
      padding-left: 28px;
      margin: 0;
      line-height: 37px;
      color: #56606b;
      font-family: 'Noto Sans KR';
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.4px;
      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 6px;
        display: inline-block;
        width: 11px;
        height: 20px;
        background: url('../../../icons/ic-bookmark-dark.svg') no-repeat center;
      }
    }
  }
  .body {
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    padding: 24px 60px 124px 100px;
    aside {
      flex: 0 0 auto;
      width: 178px;
      .btn-group {
        display: flex;
        .btn {
          width: 50%;
          height: 36px;
          border-radius: 3px;
          border: none;
          color: #424952;
          font-family: 'Noto Sans KR';
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.05px;
          background-color: #dae3ed;
          &:hover {
            background-color: #bec9d4;
          }
          &:disabled {
            opacity: 0.4;
          }
          & + .btn {
            margin-left: 6px;
          }
          &.btn-primary {
            color: #ffffff;
            background-color: #424952;
            &:hover {
              background-color: #6b7682;
            }
            &:active,
            &:focus {
              background-color: #2c3238;
            }
          }
        }
        & + .list-group {
          margin-top: 16px;
        }
      }
    }
    section {
      display: flex;
      flex: 1 1 auto;
      margin: 0 0px 0px 48px;
      padding: 0 0px 3px 2px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      article {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        margin-left: 50px;
        margin-top: 160px;
        color: #56606b;
        font-family: 'Noto Sans KR';
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.4px;
      }
    }
  }
  footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    flex: 0 0 auto;
    height: 94px;
    background-color: #e9eff5;
    padding: 30px 100px 0 326px;
    & > div {
      display: flex;
      justify-content: space-between;
      width: 960px;
    }
    .btn-group {
      display: flex;
      flex: 0 0 auto;
      .btn {
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 12px;
        border-radius: 3px;
        border: 1px solid #bec9d4;
        background-color: #ffffff;
        color: #56606b;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1px;
        line-height: 17px;
        &:hover {
          border-color: #dae3ed;
        }
        &:active,
        &:focus {
          background-color: #f5f8fa;
        }
        &:disabled {
          opacity: 0.4;
        }
        & + .btn {
          margin-left: 6px;
        }
        &.ic-check {
          svg {
            margin: 0 0 0 -6px;
            fill: #8996a3;
            transform: scale(0.6);
          }
        }
        &.ic-share {
          svg {
            margin: 0 4px 0 -4px;
            transform: scale(0.7);
            g {
              stroke: #8996a3;
            }
          }
        }
        &.ic-delete {
          use {
            fill: #8996a3;
          }
          &:before {
            content: '';
            width: 12px;
            height: 14px;
            margin-right: 6px;
            background: url('../../../icons/ic-trash-1214.svg') no-repeat center;
          }
        }
      }
    }
  }
  .noDataContent {
    display: inline-flex;
    flex-flow: column;
    margin: 104px;
    align-items: center;
    color: #8996a3;
    font-family: 'Noto Sans KR';
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.27px;
    text-align: center;
    svg {
      margin-bottom: 48px;
    }
  }
}
#Bookmark {
  position: fixed !important;
  top: 90px !important;
  left: auto !important;
  right: 0 !important;
  transform: none !important;
  width: 688px;
  height: calc(100% - 90px);
  max-width: none;
  margin: 0;
  margin-right: -688px;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22), 0 7px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s;
  .arrow {
    display: none;
  }
  &.show {
    margin-right: 0;
  }
  #bookmark {
    padding-top: 0;
    & > header {
      display: flex;
      flex-flow: row;
      flex: 0 0 auto;
      margin: 0 20px 0 35px;
      padding: 24px 0 22px;
      h3 {
        flex: 1 1 auto;
      }
      .btn-group {
        flex: 0 0 auto;
      }
      .btn-icon {
        display: flex;
        align-items: center;
        position: relative;
        width: 32px;
        height: 34px;
        padding: 0;
        margin: 0 0 0 20px;
        border: none;
        border-radius: 50%;
        background: none;
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          &.ic-layout {
            width: 24px;
            background-image: url('../../../icons/ic-layout.svg');
          }
          &.ic-close {
            background-image: url('../../../icons/ic-close.svg');
          }
        }
      }
    }
    .body {
      padding: 16px 20px 0 35px;
      overflow: hidden;
      aside {
        header {
          display: none;
        }
        .list-group {
          .list-group-item {
            &.active {
              background-color: #f0fdff;
              box-shadow: none;
            }
          }
        }
      }
      section {
        margin: -16px 0 16px 14px;
        .list-group {
          margin-top: 0;
          .list-group-item {
            width: 442px;
            padding-left: 0px;
            box-shadow: none;
            border: none;
            & + .list-group-item {
              margin-top: 0;
              border-top: 1px solid #dae3ed;
            }
            &:hover {
              padding-right: 50px;
              .ic-delete {
                display: flex;
                margin-left: 12px;
              }
            }
            &:before {
              top: 22px;
              left: 6px;
              height: 16px;
              width: 16px;
            }
            .figure {
              & + .content {
                margin-left: 12px;
              }
            }
            .btn-group {
              width: auto;
              position: absolute;
              top: calc(50% - 10px);
              right: 25px;
            }
            .btn-icon {
              display: none;
            }

            .rs-checkbox {
              padding: 0px;
              margin-right: 0px;

              .rs-checkbox-wrapper {
                top: -7px;
                left: 6px;
              }
              .rs-checkbox-wrapper::before,
              .rs-checkbox-wrapper::after {
                width: 16px;
                height: 16px;
              }
              .rs-checkbox-inner::before,
              .rs-checkbox-inner::after {
                width: 16px;
                height: 16px;
              }
              .rs-checkbox-inner::after {
                width: 12px;
                height: 18px;
                margin-top: -3px;
                margin-left: 2px;
              }
            }
          }
        }
        article {
          display: none;
        }
      }
    }
    footer {
      position: relative;
      height: 72px;
      padding: 17px 19px 0 232px;
      background-color: #f5f8fa;
      & > div {
        width: auto;
      }
    }
  }
}

.bookmarkTab {
  #forAgreeTerms,
  #mainFooter {
    display: none;
  }
}

@media all and (-ms-high-contrast:none) {
  /* IE11 */
  *::-ms-backdrop, #Bookmark {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.22), 0 7px 10px 0 rgba(0, 0, 0, 0.12)
  }
  *::-ms-backdrop, .rs-drawer-content {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.22), 0 7px 10px 0 rgba(0, 0, 0, 0.12)
  }
}