@import '../../Mixin.scss';

.GoogleSearchExceed {
  .google-exceed-alert {
    @include padding(bottom,0px);
    .google-search {
      width: 90px;
      @include padding(left,60px);
      background-image: url('../../icons/google_text.svg');
      @include background(0px 3px);
      @include padding(bottom,15px);
    }
    .grey-box{
      flex-flow: column;
      width: 700px;
      background-color: #E9EFF5;
      border: 0;
      @include padding(top,15px);
      @include padding(right,15px);
      @include padding(bottom,15px);
      @include padding(left,15px);
      font-weight: normal;
      font-size: 14px;
      .exceed-msg-area {
        font-size: 14px;
      }
      .restore-msg-area{
        @include margin(top,4px);
      }
      .new-window {
        @include margin(top,18px);
        cursor: pointer;
        height: 18px;
        width: 125px;
        color: #8996A3;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.1px;
        line-height: 18px;
        @include background("../../icons/Triangle.svg", 112px 8px);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
