@import 'lg-bootstrap-custom.scss';
@import 'Asset.scss';

html {
  height: 100%;
}
body {
  height: 100%;
  background-color: white !important;
  font-family: 'Malgun Gothic', Sans-serif;
  font-size: 16px;
  overflow: visible;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border: 3px solid #fff;
  }
  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 10px;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: #efefef;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-thumb {
    height: 50px;
    width: 50px;
    background: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  }
}
body.reservation {
  background-color: #fafafa !important;
}
.root {
  height: 100%;
  // background-color: #f5f8fa;
}
input {
  font-family: 'sans-serif';
}
input[type='text']::-ms-clear {
  display: none;
}
a {
  &:hover {
    text-decoration: none;
  }
}

pre {
  font-family: "Malgun Gothic", Sans-serif;
}

.btn:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.popover {
  z-index: 1050;
}

/*TODO: css 확인필요*/
.fade {
  &[role="dialog"] {
    z-index: 9999;
  }
}
.fade:not(.modal-backdrop) {
  &.show {
    opacity: 100 !important;
  }
}
.modal-backdrop {
  &.show {
    opacity: 0 !important;
  }
}
/*TODO: css 확인필요*/


// bootstrap tooltip이 body에 생성되어 관련 css Footer -> App 이동
#helpDesk-tooltip {
  .arrow {
    &:before {
      border-top-color: #6b7682;
    }
  }
  .tooltip-inner {
    display: flex;
    align-items: center;
    width: 185px;
    height: 56px;
    padding: 0 16px;
    background-color: #6b7682;
    border-radius: 3px;
    opacity: 1;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        position: relative;
        padding-left: 22px;
        margin: 2px 0;
        text-align: left;
        color: #ffffff;
        font-size: 12px;
        letter-spacing: 0.1px;
        &.call {
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(50% - 6px);
            display: inline-block;
            width: 11px;
            height: 11px;
            background: url('./icons/ic_call.png') no-repeat center;
          }
        }
        &.mail {
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(50% - 5px);
            display: inline-block;
            width: 11px;
            height: 11px;
            background: url('./icons/ic_mail.png') no-repeat center;
          }
        }
      }
    }
  }
}