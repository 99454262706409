@import '../../Mixin';

#moduleContainer {
  display: flex;
  flex-flow: column;
  width: 1734px;
  height: 100%;
  padding: 60px 180px 0;
  margin: 0 auto;
  transition: padding 0.5s;
  .title {
    flex: 0 0 auto;
  }
  section {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
  }
  &.detail-show {
    padding-left: 88px;
    padding-right: 272px;
  }
}