.keywordTree {
    .modal-content {
        width: 600px;
        min-height: 790px;
        border: 1px solid #DAE3ED;
        border-radius: 0;
        background-color: #FFFFFF;
        box-shadow: 0 7px 10px 0 rgba(0,0,0,0.12);
    }
    .modal-header {
        border: none;
        padding: 22px 30px;
        .modal-title {
            color: #000000;
            font-family: "Noto Sans KR";
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.2px;
        }
        .close {
            position: relative;
            right: -4px;
            width: 25px;
            height: 25px;
            margin: 0;
            padding: 0;
            outline: 0;
            opacity: 1;
            background: url('../../../../icons/ic-close-modal.svg') no-repeat center;
            span {
                display: none;
            }
        }
    }
    .modal-body {
        padding: 72px 60px 0 80px;
        h5 {
            margin-bottom: 12px;
            color: #56606B;
            font-family: "Noto Sans KR";
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.2px;
            line-height: 25px;
        }
        .input-wrapper {
            height: 53px;
            margin-bottom: 14px;
            background-color: #F5F8FA;
        }
        input {
            width: 100%;
            height: 28px;
            padding: 0 8px;
            border: 1px solid #BEC9D4;
            border-radius: 3px;
            color: #2C3238;
            font-size: 12px;
            letter-spacing: 0.1px;
            &::-webkit-input-placeholder {
                color: #8996A3 !important;
            }
            &:-ms-input-placeholder {
                color: #8996A3 !important;
            }
            &::placeholder {
                color: #8996A3 !important;
            }
            &:focus {
                box-shadow: none;
            }
        }
        .info {
            margin-bottom: 30px;
            color: #56606B;
            font-size: 13px;
            letter-spacing: 0.18px;
            line-height: 20px;
        }
        .keyword-list {
            dl {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                &+dl {
                    margin-top: 10px;
                }
            }
            dt {
                width: 70px;
                color: #8996A3;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: -0.13px;
                line-height: 25px;
            }
            dd {
                margin: 0;
                color: #424952;
                font-size: 12px;
                letter-spacing: -0.13px;
                line-height: 25px;
                &.empty {
                    &:before {
                        content: "검색 내역이 없습니다.";
                        color: #A5B3C2;
                        font-size: 12px;
                        letter-spacing: -0.13px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
    .modal-footer {
        padding: 0 30px 35px;
        border: none;
        .btn-primary {
            display: flex;
            align-items: center;
            height: 36px;
            padding: 0 12px 0 5px;
            margin: 0;
            border-radius: 3px;
            border: none;
            background-color: #424952;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.2px;
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active {
                border-color: #424952;
                background-color: #424952;
            }
            svg {
                margin-right: 2px;
                fill: #FFFFFF;
                transform: scale(0.7);
            }
            &:hover {
                background-color: #6B7682;
            }
            &:active,
            &:focus {
                background-color: #2C3238;
            }
            &:disabled {
                background-color: #424952;
                opacity: 0.4;
            }
        }
    }
}