#Pagination {
  .rs-pagination-btn {
    text-align: center;
    &:first-child a {
      margin-right: 19px;
    }
    &:last-child a {
      margin-left: 19px;
    }

    &:not(:first-child):not(:last-child) > a {
      &:hover {
        border-radius: 16px;
        background-color: #e9eff5;
      }

      -webkit-transition: none;
      transition: none;
    }
    & > a {
      width: 32px;
      height: 32px;
      padding-top: 5px;
      color: #212529;
    }

    &.rs-pagination-btn-active > a {
      border-radius: 16px;
      background-color: #dae3ed;
      color: #2c3238;
      font-weight: 700;
    }
  }

  .rs-icon-page-previous {
    font-size: 16px;
    font-weight: bold;
  }

  .rs-icon-page-next {
    font-size: 16px;
    font-weight: bold;
  }
}
