@import '../../Mixin';
@import './var';

.SearchContents {
  width:871px;
  @include margin(right,60px);
  .title {
    font-family: "Noto Sans KR";
    font-weight: 700;
    font-size:24px;
    color:#56606B;
    @include padding(left,24px);
    @include margin(bottom,32px);
    //@include margin(bottom,18px);
  }

  .title {
    .paging_numbers {
      font-size:12px;
      color:#8996A3;
      font-weight: 700;
    }
  }
  .add {
    @include margin(top,17px);
  }
  .info {
    min-height: 36px;
    font-weight: 700;
    font-size: 14px;
    color:#A5B3C2;
    @include padding(top,$search_info_pt);
    @include padding(bottom,$search_info_pb);
    .spacing {
      @include margin(right,5px);
    }
    .spacing2 {
      @include margin(right,10px);
    }
    .day {
      font-weight:400;
    }
  }
  .cb {
    @include absolute($top:21px,$left:0);
    border-color:#A5B3C2;
    width:18px;
    height:18px;
  }
  .content-wrap {
    width:700px;
    height:40px;
    color:#56606B;
    font-weight:400;
    font-size:14px;
    overflow: hidden;
  }
  .subject {
    min-height: 30px;
    color:#12A6BE;
    font-family: "Noto Sans KR";
    font-size:20px;
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: #904FF2;
    }
  }
  .list {
    @include relative();
    & .spacing {
      @include margin(left,17px);
      @include margin(bottom,0);
    }
    .list-box {
      @include margin(top,5px);
      @include margin(left,10px);
      @include margin(right,10px);
      @include margin(bottom,5px);
      @include padding(top,10px);
      @include padding(left,14px);
      @include padding(right,14px);
      @include padding(bottom,14px);
      &.active {
        background-color:#FFFFFF;
      }
    }
  }
  .social {
    @include absolute(null,0,29px,null);
    width: 110px;
    height: 17px;
    color: #6B7682;
    font-size: 11px;
    letter-spacing: 0.15px;
    line-height: 17px;
    .bookmark {
      @include padding(left,14px);
      @include background("./icons/bookmark.svg", 0px 2px);
      width: 27px;
    }
    .comment {
      @include margin(left,15px);
      @include padding(left,19px);
      @include background("./icons/comment.svg", 0px 2px);
      width: 27px;
    }
    .share {
      @include margin(left,17px);
      @include background("./icons/share3.svg", 0px 2px);
      width: 14px;
    }
  }
}